import {
  ARRAY_BLOCKCHAIN,
  ARRAY_STORAGE_TYPE,
} from "../../../constantes/value";
import {
  LABEL,
  ADDRESS,
  BLOCKCHAIN,
  ADD_WALLET,
  STORAGE_TYPE,
  WALLET_ADRESS,
  INVALID_ADDRESS,
} from "../../../constantes/app";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import { useCreateWallet } from "./hook";
import { TInputAddWallet } from "./type";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { SelectInput } from "../../../types";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Loading from "../../../shared/Loading";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { SubmitHandler, useForm } from "react-hook-form";
import { checkValidAddress } from "../../../utils/regex";
import { useManageUserSelectedID } from "../../../hook/useManageUserSelectedID";

export default function AddWallet() {
  const userName = useParams<{ userName: string }>().userName;

  const userId = useManageUserSelectedID(userName);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInputAddWallet>();

  const addWallet = useCreateWallet(reset, userId!);

  const onSubmit: SubmitHandler<TInputAddWallet> = (data_) => {
    if (!checkValidAddress(data_.address, data_.blockchain)) {
      toast.error(`${INVALID_ADDRESS} for ${data_.blockchain}`);
      return;
    }
    data_.userId = userId!;
    addWallet.mutate(data_);
  };

  return (
    <>
      <Toaster />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            borderRadius: 3,
          }}
        >
          <CardHeader
            title={WALLET_ADRESS}
            sx={{
              textAlign: "center",
            }}
          />
          <Divider
            sx={{
              margin: "0 1rem",
            }}
          />
          <CardContent
            style={{
              padding: "3.1rem",
              height: 478,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={ADDRESS}
                  variant="outlined"
                  fullWidth
                  {...register("address", { required: true })}
                  error={errors.address ? true : false}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={BLOCKCHAIN}
                  variant="outlined"
                  fullWidth
                  select
                  {...register("blockchain", { required: true })}
                  error={errors.blockchain ? true : false}
                >
                  {ARRAY_BLOCKCHAIN.map((blockchain: SelectInput) => (
                    <MenuItem key={blockchain.value} value={blockchain.value}>
                      {blockchain.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={STORAGE_TYPE}
                  variant="outlined"
                  fullWidth
                  select
                  {...register("storage_type", { required: true })}
                  error={errors.storage_type ? true : false}
                >
                  {ARRAY_STORAGE_TYPE.map((storageType: SelectInput) => (
                    <MenuItem key={storageType.value} value={storageType.value}>
                      {storageType.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={LABEL}
                  variant="outlined"
                  fullWidth
                  {...register("label", { required: true })}
                  error={errors.label ? true : false}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  disabled={
                    watch("address") === "" || watch("label") === ""
                      ? true
                      : false
                  }
                >
                  {addWallet.isPending ? (
                    <Loading size={10} color="inherit" />
                  ) : (
                    ADD_WALLET
                  )}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  );
}
