import {
  DASHBOARD_ROUTE,
  EXIT_PLAN_ROUTE,
  MANAGED_USER_ROUTE,
} from "../constantes/routes";
import {
  otherRoutes,
  managerRoutesOnly,
  clientManagementRoutes,
  clientManagementRoutesSelected,
} from "../navigation/route";
import * as React from "react";
import AppBar from "./AppBar";
import Box from "@mui/material/Box";
import { RouteType } from "../types";
import List from "@mui/material/List";
import color from "../constantes/color";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import { selectUser } from "../redux/selector";
import FlakyIcon from "@mui/icons-material/Flaky";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import FolderIcon from "@mui/icons-material/Folder";
import { selectAssetData } from "../redux/selector";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

const drawerWidth = 240;

export default function Layout({
  children,
  userName,
}: {
  children: React.ReactNode;
  userName: string | undefined;
}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const path = useLocation().pathname;
  const user = useSelector(selectUser).data;

  const userSelected = useGetUserConnectedOrSelected(userName);
  const assetData = useSelector(selectAssetData).data;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const navigate = useNavigate();

  const drawer = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 3,
          paddingLeft: 3,
          height: 64,
          backgroundColor: color.grey,
          marginBottom: 2,
        }}
      >
        <img
          src={user?.account.company_logo}
          alt="logo"
          width="50"
          style={{
            borderRadius: 50,
          }}
        />
        <Typography
          sx={{
            marginRight: 3,
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {user?.account.company_name}
        </Typography>
      </Box>

      <List>
        {clientManagementRoutes.map((route: RouteType, index: number) => (
          <NavLink
            to={
              userName
                ? route.pathSpecific
                  ? `${route.path}/${userName}`
                  : route.path
                : route.path
            }
            key={index}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <ListItem
              key={index}
              disablePadding
              sx={{
                width: "auto",
                display: "block",
                marginLeft: 2,
                marginRight: 3,
                marginBottom: 1,
                fontWeight: "bold",
                backgroundColor: path.includes(route.path)
                  ? color.bluePrimary
                  : color.white,
                color: path.includes(route.path) ? color.white : color.black,
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.2s ease-in-out",
                  backgroundColor: color.bluePrimary,
                  color: color.white,
                },
              }}
            >
              <ListItemButton>
                <ListItemIcon>{React.createElement(route.icon!)}</ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}

        {(userName || (user && !user.is_manager)) &&
          clientManagementRoutesSelected.map(
            (route: RouteType, index: number) => (
              <NavLink
                to={
                  userName
                    ? route.pathSpecific
                      ? `${route.path}/${userName}`
                      : route.path
                    : route.path
                }
                key={index}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    width: "auto",
                    display: "block",
                    marginLeft: 2,
                    marginRight: 3,
                    marginBottom: 1,
                    borderRadius: 3,
                    color: path.includes(route.path)
                      ? color.white
                      : color.black,
                    backgroundColor: path.includes(route.path)
                      ? color.bluePrimary
                      : color.white,
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s ease-in-out",
                      backgroundColor: color.bluePrimary,
                      color: color.white,
                    },
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {React.createElement(route.icon!)}
                    </ListItemIcon>
                    <ListItemText
                      primary={route.name}
                      sx={{
                        fontWeight: "bold",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )
          )}
        {user?.is_manager &&
          userName === undefined &&
          managerRoutesOnly.map((route: RouteType, index: number) => (
            <NavLink
              to={
                userName
                  ? route.pathSpecific
                    ? `${route.path}/${userName}`
                    : route.path
                  : route.path
              }
              key={index}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <ListItem
                key={index}
                disablePadding
                sx={{
                  width: "auto",
                  display: "block",
                  marginLeft: 2,
                  marginRight: 3,
                  marginBottom: 1,
                  borderRadius: 3,
                  color: path.includes(route.path) ? color.white : color.black,
                  backgroundColor: path.includes(route.path)
                    ? color.bluePrimary
                    : color.white,
                  "&:hover": {
                    transform: "scale(1.05)",
                    transition: "transform 0.2s ease-in-out",
                    backgroundColor: color.bluePrimary,
                    color: color.white,
                  },
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {React.createElement(route.icon!)}
                  </ListItemIcon>
                  <ListItemText
                    primary={route.name}
                    sx={{
                      fontWeight: "bold",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
      </List>
      {user?.is_manager && (
        <>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            MANAGER VIEW
          </Box>
          <List>
            {userName && (
              <>
                <ListItem
                  key={0}
                  disablePadding
                  sx={{
                    width: "auto",
                    display: "block",
                    marginLeft: 2,
                    marginRight: 3,
                    marginBottom: 1,
                    backgroundColor: color.white,
                    color: color.black,
                    borderRadius: 3,
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s ease-in-out",
                      backgroundColor: color.bluePrimary,
                      color: color.white,
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      navigate(DASHBOARD_ROUTE);
                    }}
                  >
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Global Data" />
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {!userName && (
              <NavLink
                to={MANAGED_USER_ROUTE}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <ListItem
                  key={1}
                  disablePadding
                  sx={{
                    width: "auto",
                    display: "block",
                    marginLeft: 2,
                    marginRight: 3,
                    marginBottom: 1,
                    borderRadius: 3,
                    color:
                      path === MANAGED_USER_ROUTE ? color.white : color.black,
                    backgroundColor:
                      path === MANAGED_USER_ROUTE
                        ? color.bluePrimary
                        : color.white,
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.2s ease-in-out",
                      backgroundColor: color.bluePrimary,
                      color: color.white,
                    },
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Client" />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}
          </List>
        </>
      )}
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        OTHERS
      </Box>
      <List>
        {otherRoutes.map((route: RouteType, index: number) => (
          <NavLink
            to={route.path}
            key={index}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <ListItem
              key={index}
              disablePadding
              sx={{
                width: "auto",
                display: "block",
                marginLeft: 2,
                marginRight: 3,
                marginBottom: 1,
                borderRadius: 3,
                color: path.includes(route.path) ? color.white : color.black,
                backgroundColor: path.includes(route.path)
                  ? color.bluePrimary
                  : color.white,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.2s ease-in-out",
                  backgroundColor: color.bluePrimary,
                  color: color.white,
                },
              }}
            >
              <ListItemButton>
                <ListItemIcon>{React.createElement(route.icon!)}</ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}

        {assetData &&
          ((userSelected && Object.keys(userSelected!).length > 0) ||
            (user && !user.is_manager)) && (
            <NavLink
              to={userName ? `${EXIT_PLAN_ROUTE}/${userName}` : EXIT_PLAN_ROUTE}
              key={"exit-plan"}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <ListItem
                key={"exit-plan"}
                disablePadding
                sx={{
                  width: "auto",
                  display: "block",
                  marginLeft: 2,
                  marginRight: 3,
                  marginBottom: 1,
                  borderRadius: 3,
                  color: path.includes(EXIT_PLAN_ROUTE)
                    ? color.white
                    : color.black,
                  backgroundColor: path.includes(EXIT_PLAN_ROUTE)
                    ? color.bluePrimary
                    : color.white,
                  "&:hover": {
                    transform: "scale(1.05)",
                    transition: "transform 0.2s ease-in-out",
                    backgroundColor: color.bluePrimary,
                    color: color.white,
                  },
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <FlakyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Exit Plan" />
                </ListItemButton>
              </ListItem>
            </NavLink>
          )}
      </List>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar isClosing={isClosing} userName={userName} />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 3,
          pr: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
