// YEXO
export const YEXO = "YEXO";

// LOGIN
export const LOGIN = "Login";
export const SINGIN = "Welcome to your asset management";
export const USERNAME = "Username";
export const PASSWORD = "Password";
export const LOGIN_ERROR = "Invalid username or password";
export const WELCOME = "Welcome";
export const ENJOY = "Enjoy crypto volatility";
export const THANKS = "thanks to AI-based bots";
export const FORGOT_PASSWORD = "Forgot password?";
export const REQUIRED_PASSWORD = "Password is required";
export const REQUIRED_USERNAME = "Username is required";

// APPBAR
export const EURORDOLLAR = "Euro or Dollar";

// SUB APP BAR
export const SAYHI = "Hi";
export const CREATEBOT = "Create a bot";
export const ADMIN = "Admin";
export const MANAGEUSER = "Manage users";
export const DERIVATIVES = "Derivatives";

// Error
export const ERROR_FETCH_DATA_API = "An error occurred while fetching data";
export const ADVANCED = "Advanced";
export const REQUIRED_PUBLIC_KEY = "Public key is required";
export const REQUIRED_PRIVATE_KEY = "Private key is required";
export const ADD_PAYMIUM_KEY = "Add Paymium Key";
export const ADD_BITCOIN_AVENUE_KEY = "Add Bitcoin Avenue Key";
export const ADD_BINANCE_KEY = "Add Binance Key";
export const ADDED_BITCOIN_AVENUE_KEY_SUCCESFULLY =
  "  Added Bitcoin Avenue keys successfully";
export const ADD_PAYMIUM_KEY_SUCCESFULLY = "  Added Paymium keys successfully";
export const ERROR_ADDING_KEY = "An error occurred while adding the key";
export const ADD_WALLET_ADDRESS_SUCCESFULLY =
  " Added wallet address successfully";
export const ERROR_ADDING_WALLET_ADDRESS =
  "An error occurred while adding the wallet address";
export const ERROR_ADDING_WALLET_ADDRESS_EXIST =
  "Wallet with this User, Address and Blockchain already exists.";
export const ADD_WALLET = "Add a new wallet";
export const INVALID_ADDRESS = "Invalid address";
export const ADRESS_ALREADY_EXIST = "Address already exist";
export const EDIT_KEYS = "Edit keys";
export const EDIT_WALLET = "Edit wallet";
export const DELETE_WALLET = "Delete wallet";
export const DELETE_KEY = "Delete key";
export const CANCEL = "Cancel";
export const SAVE = "Save";
export const EDIT_KEYS_SUCCESS = "Edit keys successfully";
export const EDIT_WALLET_SUCCESS = "Edit wallet successfully";
export const DELETE_WALLET_SUCCESS = "Delete wallet successfully";
export const DELETE_KEY_SUCCESS = "Delete key successfully";
export const EDIT_KEYS_ERROR = "Edit keys failed";
export const EDIT_WALLET_ERROR = "Edit wallet failed";
export const DELETE_WALLET_ERROR = "Delete wallet failed";
export const DELETE_KEY_ERROR = "Delete key failed";
export const NOTHING_CHANGED = "Nothing changed";
export const ERROR = "Error";
export const NO_DATA = "No data";
export const JOINED_ON = "Joined on";
export const NO_MANAGER = "No manager found except you";
export const NO_CLIENT = "No client found for you";
export const ERROR_REGISTERING_USER =
  "An error occurred while registering user";
export const SUCCESS_REGISTERING_USER = "User registered successfully";
export const SUCCESS_REGISTERING_MANAGER = "Manager registered successfully";
export const ERROR_REGISTERING_MANAGER =
  "An error occurred while registering manager";
export const ADD_CLIENT = "Add a new client";
export const EDIT_CLIENT = "Edit client";
export const DELETE_CLIENT = "Delete client";
export const EDIT_CLIENT_SUCCESS = "Edit client successfully";
export const DELETE_CLIENT_SUCCESS = "Delete client successfully";
export const EDIT_CLIENT_ERROR = "Edit client failed";
export const DELETE_CLIENT_ERROR = "Delete client failed";
export const ADD_MANAGER = "Add a new manager";
export const EDIT_MANAGER = "Edit manager";
export const EDIT_USER = "Edit user";
export const DELETE_MANAGER = "Delete manager";
export const EDIT_MANAGER_SUCCESS = "Edit manager successfully";
export const DELETE_MANAGER_SUCCESS = "Delete manager successfully";
export const EDIT_MANAGER_ERROR = "Edit manager failed";
export const DELETE_MANAGER_ERROR = "Delete manager failed";
export const ADD = "Add";
export const EDIT = "Edit";
export const ERROR_UMIQUE_EMAIL = "Email already exist";
export const SUCCES_EDITING_USER = "User edited successfully";
export const SUCCES_EDITING_MANAGER = "Manager edited successfully";
export const ERROR_EDITING_USER = "An error occurred while editing user";
export const ERROR_EDITING_MANAGER = "An error occurred while editing manager";
export const ASSIGN_CLIENT_TO_MANAGER = "Assign client to manager";
export const ASSIGN_CLIENT_TO_ACCOUNT_MANAGER =
  "Assign client to account manager";
export const ASSIGN_CLIENT_TO_MANAGER_SUCCESS =
  "Assign client to manager successfully";
export const ASSIGN_CLIENT_TO_MANAGER_ERROR =
  " An error occurred while assigning client to manager";
export const ASSIGN = "Assign";
export const MY_PROFILE = "My profile";
export const MY_INFORMATIOM = "My information";
export const EDITIN_FORMATION = "Edit Information";
export const UPDATE = "Update";
export const UPDATE_PASSWORD = "Change password";
export const EDIT_PASSWORD_SUCCESS = "Edit Password Successfully";
export const EDIT_PASSWORD_ERROR = "An error occurred while editing password";
export const CHANGE_ROLE = "Change role";
export const VIEW_ALL = "View all";
export const SELL = "Sell";
export const BUY = "Buy";
export const TRANSFER = "Transfer";
export const SEARCH_BY_TXHASH = "Search by txHash or symbol";
export const FILTER_BY_BLOCKCHAIN = "Filter by blockchain";
export const BUY_CRYPTO = "Buy Crypto";
export const SELL_CRYPTO = "Sell Crypto";
export const TXHASH = "TxHash";
export const BLOCKCHAIN = "Blockchain";
export const CONTRACT_ADDRESS = "Contract Address";
export const FROM = "From";
export const TO = "To";
export const API_CONNECTION = "API Connection";
export const PUBLIC_KEY = "Public Key";
export const PRIVATE_KEY = "Private Key";
export const BITCOINAVENUE = "BitcoinAvenue";
export const BINANCE = "Binance";
export const PAYMIUM = "Paymium";
export const WALLET_ADRESS = "Wallet Address";
export const SYMBOL = "Symbol";
export const ADDRESS = "Address";
export const STORAGE_TYPE = "Storage Type";
export const LABEL = "Label";
export const ADDED_ON = "Added on";
export const UPDATED_ON = "Updated on";
export const WANT_DELETE = "Are you sure you want to delete this";
export const YES_DELETE = "Yes, delete it!";
export const DELETED = "Deleted!";
export const YOUR = "Your";
export const ADRESS_DELETED = "adress has been deleted.";
export const CANCELLED = "Cancelled";
export const ERROR_DELETING_USER = "An error occurred while deleting the";
export const FIRST_NAME = "First name";
export const LAST_NAME = "Last name";
export const PROFIL_IMAGE_URL = "Profil image url";
export const OLD_PASSWORD = "Old password";
export const NEW_PASSWORD = "New password";
export const RETYPE_PASSWORD = "Retype password";
export const MANAGE_USER = "Manage user";
export const SELECTED_USER = "Select user you want to manage";
export const NO_USER_FOUND = "No user found";
export const MANAGE_ACCOUNT = "Manage account";
export const LIST_CLIENT_NO_MANAGER = "List of clients without manager";
export const OUR_CLIENT_LIST = "Our client list";
export const LIST_CLIENT_NO_ACCOUNT_MANAGER =
  "List of clients without account manager";
export const ALL = "All";
export const OUR = "Our";
export const CREATED_AT = "Created At";
export const CLIENT_INFO = "Client Information";
export const USER_NAME = "Username";
export const ACTIONS = "Actions";
export const SEARCH_BY_FIRSTNAME_OR_LASTNAME =
  "Enter original name or hash to search";
export const NOT_ALLOWED_STAFF_CONNECTED =
  "This account is not allowed to connect to this platform";
export const ERROR_USERNAME_UNIQUE = "Name already used for another user";
export const DOWNLOAD_XLSX_TRANSACTION = "Download transaction";
export const SURE_DOWNLOAD = "Are you sure to download this transaction";
export const DOWNLOAD = "Download";
export const YES_DOWNLOAD = "Yes, Download it!";
export const SUCCESS_DOWNLOAD = "Download successfully completed";
export const ERROR_DOWNLOAD = "An error occurred while downloading";
export const EXISTING_USER = "User already exists for this username";
export const PRICE_HELPER_TEXT =
  "Sorry, data could not be retrieved or calculated";

export const ASSET = "Asset";
export const OWNED_ASSET = "Owned Asset";
export const TRADING_PAIR = "Trading Pair";
export const PRICE = "Price";
export const NET_WORTH = "Net Worth";
export const INVESTMENT = "Investment";
export const PNL_VALUE = "PnL Value";
export const PNL_PERCENTAGE = "PnL Percentage";
export const FEES = "Fees";
export const AVERAGE_PURCHASE_PRICE = "Average Purchase Price";
export const PL = "P&L";
export const FIAT_BALANCE = "Fiat Balance";
export const TOKEN_BALANCE = "Token Balance";
export const CURRENT_PRICE = "Current Price";
export const OWNED = "Owned";
export const AVG_ACQUISITION_CONST = "Avg acquisition cost";
export const CURRENT_PNL = "Current PnL";
export const NO_EXIT_PLAN = "You have no Exit Plans";
export const YOUR_EXIT_PLAN = "Your Exit Plans";
export const ADD_EXIT_PLAN = "Your New Exit Plan";
export const CREATE = "Create";
export const NAME = "Name";
export const TOKEN = "Token";
export const TPS = "TPs";
export const PNL = "PnL";
export const DETAILS = "Details";
export const EXIT_PRICE = "Exit Price";
export const PERCENT_DISTRIBUTION = "% Distribution";
export const TOKEN_SOLD = "# Tokens Sold";
export const TX_AMOUNT = "Tx Amount";
export const TX_PNL = "Tx PnL(val)";
export const EXIT_PLAN_FOR = "Exit Plan for";
export const COULD_NOT_RESOLVE_DATA =
  "Could not resolve data  snapshot of the day";
export const ERROR_PERCENTAGE_DISTRIBUTION =
  "The sum of distribution percent should not exceed 100";
export const REMAINING_TOKENS = "Remaining tokens";
export const VALUE_ALWAYS_USD = "Value always in $";
export const FILTER_BY_DATE = "Filter by date";
export const DISPLAY_PORTFOLIO_DATE = "Display portfolio to date";
export const TIME_MACHINE = "Time Machine";
export const DASHBOARD = "Dashboard";
export const PORTFOLIO = "Portfolio";
export const TRANSACTION = "Transactions";
export const EXIT_PLANS = "Exit Plans";
export const ASSETS_IN_PORTFOLIO = "Assets in Portfolio";
export const YOUR_WALLETS = "Your Wallets";
export const EXIT_PLAN_DETAILS = "Exit Plan Details";
export const SETTINGS = "Settings";
export const CRYPTO_RATING = "Crypto Rating";
export const MANAGE_CLIENT_OR_TEAM = "Manage Client or Team";
export const PRICE_CALCULATOR = "Price calculator";
export const CHOOSE_CRYPTO = "Choose a Crypto";
export const ERROR_FETCHING_CRYPTO_PRICE = "Error: Price could not be fetched";
export const DELETE_FIELDS_PRICE_CRYPTO = "Delete this field";
export const CHOOSE_DATE_IN_THE_PAST =
  "Please choose a date in the past or today.";
export const CHOOSE_DATE = "Please choose a date!";
export const FAILED_FETCH_PRICE = "Failed to fetch price.";
export const STABLE_COIN_BALANCE = "Stable coin balance";
export const PORTFOLIO_BALANCE = "Portfolio Balance";
export const ASSET_ALLOCATION = "Asset Allocation";
export const ASSET_LIST = "Asset List";
export const CLOSE = "Close";
export const GENERATE = "Generate";
export const GENERATE_REPORT = " Generate a report";
export const PREVIEW = "Preview";
export const NEXT = "Next";
export const WHATS_KIND = "What's kind?"