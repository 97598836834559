import {
  UPDATE,
  USERNAME,
  EDITIN_FORMATION,
  PROFIL_IMAGE_URL,
} from "../../../constantes/app";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import { Toaster } from "react-hot-toast";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import { useEditMyInformation } from "./hook";
import TextField from "@mui/material/TextField";
import { TInputsEditInformation } from "./type";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { selectUser } from "../../../redux/selector";
import { SubmitHandler, useForm } from "react-hook-form";

export default function EditInformation({
  setEditInformation,
  setToast,
  setTypeOfToast,
}: {
  setEditInformation: (editInformation: boolean) => void;
  setToast: (isToast: boolean) => void;
  setTypeOfToast: (typeOfToast: string) => void;
}) {
  const userData = useSelector(selectUser).data;

  const { watch, reset, register, handleSubmit } =
    useForm<TInputsEditInformation>();

  const [initialValues, setInitialValues] = useState<TInputsEditInformation>({
    profile_image_url: userData?.profile_image_url!,
  });

  useEffect(() => {
    setInitialValues({
      profile_image_url: userData?.profile_image_url!,
    });
  }, [userData]);

  const isFormUnchanged =
    watch("profile_image_url") === initialValues.profile_image_url;

  const editInformation = useEditMyInformation(
    reset,
    setTypeOfToast,
    setToast,
    setEditInformation
  );

  const onSubmit: SubmitHandler<TInputsEditInformation> = (data) => {
    data.id = userData?.id;
    editInformation.mutate(data);
  };

  return (
    <>
      <Toaster />
      <Card
        sx={{
          borderRadius: 3,
        }}
      >
        <CardHeader title={EDITIN_FORMATION} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={USERNAME}
                  variant="outlined"
                  fullWidth
                  disabled
                  defaultValue={userData?.username}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label={PROFIL_IMAGE_URL}
                  variant="outlined"
                  fullWidth
                  defaultValue={userData?.profile_image_url}
                  type="url"
                  {...register("profile_image_url", { required: true })}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={editInformation.isPending || isFormUnchanged}
                >
                  {editInformation.isPending ? (
                    <Loading size={20} color="inherit" />
                  ) : (
                    UPDATE
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
