import {
  ASSET,
  CLOSE,
  GENERATE,
  PNL_VALUE,
  NET_WORTH,
  INVESTMENT,
  OWNED_ASSET,
  PNL_PERCENTAGE,
  AVERAGE_PURCHASE_PRICE,
} from "../../../constantes/app";
import Box from "@mui/material/Box";
import OverView from "../../Overview";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import { useParams } from "react-router-dom";
import AssetListing from "../../AssetListing";
import { useReactToPrint } from "react-to-print";
import Typography from "@mui/material/Typography";
import { useRef, useState, useMemo } from "react";
import AssetAllocation from "../../AssetAllocation";
import { calculatePY } from "../../../utils/calcul";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { AggregatedDataSnapshot } from "../../../types";
import { getTextByLanguage } from "../../../utils/function";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { selectDisplayPortfolio } from "../../../redux/selector";
import { formatDateByLanguage } from "../../../utils/converting";
import { useFilterSnapshots } from "../../../hook/useFilterSnapshots";
import { useInitializeData } from "../../../hook/useInitializeDataDashboard";
import { useGetUserConnectedOrSelected } from "../../../hook/useGetUserConnectedOrSelected";

export default function PreviewMonthlyReport({
  open,
  handleClose,
  titleReport,
  name,
  surname,
  comment,
  language,
  today,
}: {
  open: boolean;
  handleClose: () => void;
  titleReport: string;
  name: string;
  surname: string;
  comment: string;
  language: "fr" | "en";
  today: string;
}) {
  const dispatch = useDispatch();

  const userName = useParams<{ userName: string }>().userName;
  const subTitle = getTextByLanguage("DEFAULT_SUBTITLE", language);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
  });

  // state
  const [title, setTitle] = useState<string>("overview");
  const [selectData, setSelectData] = useState<
    AggregatedDataSnapshot | undefined
  >();

  // Récupère tous les snapshots nécessaires et les valeurs mémorisées
  const { asset, snapshot, portfolioSnapshot } = useInitializeData(
    dispatch,
    userName
  );

  // selectors
  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const userSelected = useGetUserConnectedOrSelected(userName);

  // Filtrage des snapshots
  const { snapshotToday, portfolioSnapshotToday } = useFilterSnapshots({
    snapshot,
    portfolioSnapshot,
    userSelected,
    displayPortFolio,
    today,
  });

  // Calculs et données dérivées
  const earliestSnapshotDate = useMemo(() => {
    if (!snapshot.data) return null;
    return snapshot.data.reduce((earliest, snap) => {
      const currentDate = new Date(snap.today);
      return currentDate < earliest ? currentDate : earliest;
    }, new Date("9999-12-31"));
  }, [snapshot.data]);

  const daysSinceEarliestSnapshot = useMemo(() => {
    if (!earliestSnapshotDate) return 0;
    const today_ = new Date();
    const diffInMilliseconds =
      today_.getTime() - earliestSnapshotDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [earliestSnapshotDate]);

  const diffInDaysSelectedDate = useMemo(() => {
    if (!earliestSnapshotDate || !today) return 0;
    const todaySelectedDate = new Date(today);
    const diffInMilliseconds =
      todaySelectedDate.getTime() - earliestSnapshotDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [earliestSnapshotDate, today]);

  // Calcul d'APY
  const apy = useMemo(() => {
    return calculatePY(
      parseFloat(selectData?.crypto_net_worth!),
      parseFloat(selectData?.portfolio_investment!),
      diffInDaysSelectedDate
    );
  }, [selectData, diffInDaysSelectedDate]);

  // Formattage des dates
  const formattedBalanceDate = useMemo(() => {
    return formatDateByLanguage(new Date(), language);
  }, [language]);

  const formattedEarliestSnapshotDate = useMemo(() => {
    if (!earliestSnapshotDate)
      return getTextByLanguage("NO_DATE_AVAILABLE", language);
    return formatDateByLanguage(earliestSnapshotDate, language);
  }, [earliestSnapshotDate, language]);

  // isReport true if today < current Date else false if today is current date
  const isReportLastDate = useMemo(() => {
    const todayDate = new Date();
    const dateReport = new Date(today);
    if (todayDate.getDate() === dateReport.getDate()) return false;
    return todayDate.getTime() > dateReport.getTime();
  }, [today]);

  const isAllData = useMemo(() => {
    return new Date().getDate() === new Date(today).getDate();
  }, [today]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent
        ref={contentRef}
        sx={{
          padding: "10px",
          flexWrap: "wrap",
          gap: "16px",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            padding: "14px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#2F4F59",
              color: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px 24px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              {titleReport.toUpperCase()}
            </Typography>{" "}
            <Avatar
              src={userSelected?.account.company_logo}
              alt="Logo"
              sx={{
                width: 60,
                height: 60,
              }}
            />
          </Box>
          <Box paddingLeft={15} paddingBottom={5}>
            <Typography paddingTop={5} fontSize={30}>
              <u
                style={{
                  textDecoration: "underline 5px",
                  textUnderlineOffset: "10px",
                }}
              >
                {subTitle.split(" ")[0]}
              </u>{" "}
              {subTitle.split(" ")[1]} / {`${name} ${surname}`}
            </Typography>
            <Box paddingTop={3}>
              <Typography>
                {getTextByLanguage("BALANCE_SHEET_DATE", language)} :{" "}
                {formattedBalanceDate}
              </Typography>
              <Typography>
                {getTextByLanguage("FIRST_PURCHASE", language)} :{" "}
                {formattedEarliestSnapshotDate}{" "}
                {earliestSnapshotDate &&
                  `(${daysSinceEarliestSnapshot} ${getTextByLanguage(
                    "DAY",
                    language
                  )})`}
              </Typography>
              <Typography fontWeight={"bold"}>
                {getTextByLanguage("APY", language)} :{" "}
                <span
                  style={{
                    color: isNaN(apy) ? "black" : apy >= 0 ? "green" : "red",
                  }}
                >
                  {isNaN(apy) ? "0" : apy.toFixed(2)} %
                </span>
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 6,
              }}
            >
              <OverView
                snapshotToday={snapshotToday}
                snapshot={snapshot}
                selectData={selectData}
                setSelectData={setSelectData}
                title={title}
                setTitle={setTitle}
                dataPortfolio={portfolioSnapshotToday}
                portfolioSnapshot={portfolioSnapshot}
                overview_only={true}
                isTranslation={true}
                language={language}
                isReportLastDate={isReportLastDate}
              />
            </Grid>
            <Grid size={{ xs: 6 }}>
              <AssetAllocation
                asset={asset}
                snapshotToday={snapshotToday}
                isTranslation={true}
                language={language}
                isReport={isReportLastDate}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <AssetListing
                asset={asset}
                snapshot={snapshotToday}
                isLoadingSnapshot={snapshot.isLoading}
                isErrorSnapshot={snapshot.isError}
                errorSnapshot={snapshot.error}
                enableFilter_={false}
                isTranslation={true}
                language={language}
                isReportLastDate={isReportLastDate}
                colums={[
                  ASSET,
                  PNL_VALUE,
                  NET_WORTH,
                  INVESTMENT,
                  OWNED_ASSET,
                  PNL_PERCENTAGE,
                  AVERAGE_PURCHASE_PRICE,
                ]}
                isAllData={isAllData}
              />
            </Grid>
          </Grid>
          {comment.length > 0 && (
            <Box
              marginLeft={15}
              marginRight={15}
              marginTop={2}
              border={1}
              padding={2}
              flexWrap={"wrap"}
              gap={1}
            >
              <Typography fontWeight={"bold"}>
                {getTextByLanguage("MANAGER_COMMENT", language)} :
              </Typography>
              <Typography>{comment}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          {CLOSE}
        </Button>
        <Button
          variant="contained"
          onClick={() => reactToPrintFn()}
          disabled={
            snapshot.isLoading || portfolioSnapshot.isLoading || asset.isLoading
          }
          startIcon={<PictureAsPdfIcon />}
        >
          {GENERATE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
