import {
  BUY,
  TO,
  SELL,
  FROM,
  TXHASH,
  TRANSFER,
  BUY_CRYPTO,
  SELL_CRYPTO,
  CONTRACT_ADDRESS,
} from "../../../constantes/app";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { Transaction } from "../../../types/transaction";
import { selectEuroDollar } from "../../../redux/selector";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { convertTimestampToDate } from "../../../utils/converting";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export default function InfoTransaction({ data }: { data: Transaction }) {
  const euroDollar = useSelector(selectEuroDollar);

  return (
    <TableRow>
      <TableCell
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: 2,
          }}
        >
          {data.transaction_type === "BUY" ? (
            <ArrowCircleUpIcon color="success" />
          ) : data.transaction_type === "SELL" ? (
            <ArrowCircleDownIcon color="error" />
          ) : (
            <CompareArrowsIcon color="info" />
          )}
        </Box>
        <Box display={{}}>
          <Typography fontWeight={"bold"}>
            {data.transaction_type === "BUY"
              ? `${BUY_CRYPTO}`
              : data.transaction_type === "SELL"
              ? `${SELL_CRYPTO}`
              : `${TRANSFER}`}{" "}
            - {convertTimestampToDate(data.date)}
          </Typography>
          <Typography color="text.secondary" fontWeight={"bold"}>
            {data.transaction_type === "BUY"
              ? `${BUY} ` +
                data.asset_symbol +
                ` : ${Math.abs(parseFloat(data.asset_amount)).toFixed(5)} @ ${
                  euroDollar.currency === "$"
                    ? `$${parseFloat(data.price_usd).toFixed(2)}`
                    : `${parseFloat(data.price_eur).toFixed(2)}€`
                } `
              : data.transaction_type === "SELL"
              ? `${SELL} ` +
                data.asset_symbol +
                ` : ${Math.abs(parseFloat(data.asset_amount)).toFixed(5)} @ ${
                  euroDollar.currency === "$"
                    ? `$${parseFloat(data.price_usd).toFixed(2)}`
                    : `${parseFloat(data.price_eur).toFixed(2)}€`
                }`
              : `${TRANSFER} ` +
                data.asset_symbol +
                ` : ${Math.abs(parseFloat(data.asset_amount)).toFixed(5)}`}
          </Typography>
          {data.blockchain && (
            <Typography color="text.secondary" fontWeight={"bold"}>
              {"Blockchain : " + data.blockchain}
            </Typography>
          )}
          <Typography color="text.secondary" fontWeight={"bold"}>
            {"Medium : " + data.medium}
          </Typography>
          <Typography color="text.secondary" fontWeight={"bold"}>
            {"Platform : " + data.platform}
          </Typography>

          {data.tx_hash && (
            <Typography color="text.secondary" fontWeight={"bold"}>
              {TXHASH}: {data.tx_hash}
            </Typography>
          )}
          {data.contract_address && (
            <Typography color="text.secondary" fontWeight={"bold"}>
              {CONTRACT_ADDRESS} : {data.contract_address}
            </Typography>
          )}
          {data.from_address && (
            <Typography color="text.secondary" fontWeight={"bold"}>
              {FROM} : {data.from_address}
            </Typography>
          )}
          {data.to_address && (
            <Typography color="text.secondary" fontWeight={"bold"}>
              {TO} : {data.to_address}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          color:
            data.transaction_type === "BUY"
              ? "success.main"
              : data.transaction_type === "SELL"
              ? "error.main"
              : "info.main",
          fontWeight: "bold",
        }}
      >
        {data.transaction_type !== "TRANSFER" && (
          <>
            {euroDollar.currency === "$" && "$"}
            {euroDollar.currency === "€"
              ? parseFloat(data.tx_amount_eur).toFixed(8)
              : parseFloat(data.tx_amount_usd).toFixed(8)}

            {euroDollar.currency === "€" && "€"}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
