import Loading from "../Loading";
import Box from "@mui/material/Box";

export const CheckUserIdHelper = ({ size = 10 }: { size?: number }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading size={size} />
      </Box>
    </Box>
  );
};
