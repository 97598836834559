import { useState } from "react";
import Box from "@mui/material/Box";
import Loading from "../shared/Loading";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import AddExitPlan from "../features/ExitPlan/Add";
import ListExitPlan from "../features/ExitPlan/List";
import { useGetExitPlan } from "../hook/useGetExitPlan";
import { useGetSnapshot } from "../hook/useGetSnapshot";
import { ADD, NO_EXIT_PLAN, YOUR_EXIT_PLAN } from "../constantes/app";
import { useManageUserSelectedID } from "../hook/useManageUserSelectedID";

export default function ExitPlan() {
  const userName = useParams<{ userName: string }>().userName;

  const [addOpen, setAddOpen] = useState(false);

  const userId = useManageUserSelectedID(userName);
  const asset = useGetSnapshot(`${userId}`);
  const exitPlan = useGetExitPlan(userId!);

  return (
    <>
      {
        <AddExitPlan
          open={addOpen}
          setOpen={setAddOpen}
          asset={asset}
          userId={userId!}
        />
      }
      {exitPlan.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "gray",
          }}
        >
          <Loading color="inherit" />
        </Box>
      ) : exitPlan.isError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          color="red"
        >
          {exitPlan.error.message}
        </Box>
      ) : (
        <>
          <Typography marginTop={4} variant="h4" fontWeight={"bold"}>
            {exitPlan.data!.length > 0 ? YOUR_EXIT_PLAN : NO_EXIT_PLAN}
          </Typography>
          {exitPlan.data!.length > 0 && <ListExitPlan data={exitPlan.data!} />}
        </>
      )}

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={() => setAddOpen(true)}
      >
        {ADD}
      </Button>
    </>
  );
}
