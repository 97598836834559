import {
  YOUR,
  LABEL,
  ADDRESS,
  ADDED_ON,
  YES_DELETE,
  UPDATED_ON,
  WANT_DELETE,
  STORAGE_TYPE,
  ADRESS_DELETED,
  ERROR_DELETING_USER,
} from "../../../../constantes/app";
import Swal from "sweetalert2";
import { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import ModalEditWallet from "../../EditWallet";
import EditIcon from "@mui/icons-material/Edit";
import { Wallet } from "../../../../types/keys";
import TableCell from "@mui/material/TableCell";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { WALLET } from "../../../../constantes/query";
import { useQueryClient } from "@tanstack/react-query";
import { deleteWallet } from "../../../../services/keys";
import withReactContent from "sweetalert2-react-content";
import { convertDateFormatIso } from "../../../../utils/converting";
import { useManageUserSelectedID } from "../../../../hook/useManageUserSelectedID";

export default function ListWalletAdress({ data }: { data: Wallet }) {
  const ethereumLogo: string =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSohq_0PdZa6YFvYge-wIvW86O8s3kjO23MQbyS5a7zNA&s";
  const bitcoinLogo: string =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png";

  const [openModalEditWallet, setOpenModalEditWallet] = useState(false);
  const [dataEditWallet, setDataEditWallet] = useState<Wallet | null>(null);

  const handleOpenModalEditWallet = () => {
    setDataEditWallet(data);
    setOpenModalEditWallet(true);
  };

  const userName = useParams<{ userName: string }>().userName;
  const MySwal = withReactContent(Swal);
  const userId = useManageUserSelectedID(userName);
  const queryClient = useQueryClient();

  return (
    <>
      <Toaster />
      {openModalEditWallet && (
        <ModalEditWallet
          open={openModalEditWallet}
          handleClose={() => setOpenModalEditWallet(false)}
          data_={dataEditWallet!}
          userId={userId!}
        />
      )}
      <TableRow>
        <TableCell
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={data.blockchain === "ETHEREUM" ? ethereumLogo : bitcoinLogo}
              sx={{
                width: 50,
                height: 50,
                borderRadius: 50,
                borderWidth: 2,
                marginRight: 1,
              }}
            />
          </Box>
          <Box>
            <Typography fontWeight={"bold"}>
              {ADDRESS} : {data.address}
            </Typography>
            <Typography fontWeight={"bold"}>
              {STORAGE_TYPE} : {data.storage_type}
            </Typography>
            <Typography fontWeight={"bold"}>
              {LABEL} : {data.label}
            </Typography>
            <Typography color="text.secondary" fontWeight={"bold"}>
              {ADDED_ON} : {convertDateFormatIso(data.created_at)}
            </Typography>
            {data.updated_at && (
              <Typography color="text.secondary" fontWeight={"bold"}>
                {UPDATED_ON} : {convertDateFormatIso(data.updated_at)}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpenModalEditWallet}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              MySwal.fire({
                title: "",
                text: `${WANT_DELETE} ${data.blockchain} ${ADDRESS}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `${YES_DELETE}`,
                reverseButtons: true,
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    deleteWallet(data.id)
                      .then((res) => {
                        queryClient.invalidateQueries({
                          queryKey: [WALLET, userId],
                        });
                        toast.success(
                          `${YOUR} ${data.blockchain} ${ADRESS_DELETED}`
                        );
                      })
                      .catch((error) => {
                        toast.error(`${ERROR_DELETING_USER}`);
                      });
                  }
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
