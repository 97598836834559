import React from "react";
import Grid from "@mui/material/Grid2";
import { Toaster } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { User } from "../../../types/user";
import { InputsCreateUser } from "../type";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Loading from "../../../shared/Loading";
import { useCreate } from "../hook/useCreate";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { SubmitHandler, useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { selectUser, selectManager } from "../../../redux/selector";
import { ADD, CANCEL, ADD_CLIENT, ADD_MANAGER } from "../../../constantes/app";

export default function CreateClientOrTeam({
  open,
  handleClose,
  isManager,
}: {
  open: boolean;
  handleClose: () => void;
  isManager?: boolean;
}) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const manager = useSelector(selectManager).data;
  const userConnected = useSelector(selectUser).data;
  const [isAccountManager, setIsAccountManager] =
    React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputsCreateUser>();

  const create = useCreate(
    reset,
    handleClose,
    isManager!,
    isAccountManager,
    setIsAccountManager,
    userConnected?.account.id!
  );

  const onChangeAccountManager = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccountManager(e.target.checked);
  };

  const onSubmit: SubmitHandler<InputsCreateUser> = (data) => {
    create.mutate(data);
  };

  return (
    <>
      <Toaster />
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
        sx={{
          marginTop: -50,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
            }}
          >
            {isManager ? ADD_MANAGER : ADD_CLIENT}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={errors.first_name ? true : false}
                  {...register("first_name", { required: true })}
                />
              </Grid>

              {!isManager && (
                <>
                  <Grid
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Manager"
                      variant="outlined"
                      fullWidth
                      select
                      error={!!errors.manager}
                      {...register("manager", { required: true })}
                    >
                      {[
                        userConnected!, // Place the connected user first
                        ...manager!.filter(
                          (manager: User) => manager.id !== userConnected!.id
                        ), // Then filter out the connected user from the rest of the managers
                      ].map((manager: User) => (
                        <MenuItem key={manager.id} value={manager.id}>
                          {manager.id === userConnected!.id
                            ? "Me"
                            : manager.first_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
              {isManager && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch name="gilad" onChange={onChangeAccountManager} />
                    }
                    label="Is Account Manager"
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={
                watch("first_name") === "" ||
                watch("last_name") === "" ||
                watch("manager") === ""
                  ? true
                  : false || watch("account_manager") === ""
                  ? true
                  : false
              }
            >
              {create.isPending ? <Loading size={10} color="inherit" /> : ADD}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
