import {
  LOGIN,
  SINGIN,
  USERNAME,
  PASSWORD,
  LOGIN_ERROR,
  REQUIRED_PASSWORD,
  REQUIRED_USERNAME,
  NOT_ALLOWED_STAFF_CONNECTED,
} from "./../../constantes/app";
import { Inputs } from "./type";
import { useState } from "react";
import { useLogin } from "./hook";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import { Toaster } from "react-hot-toast";
import Divider from "@mui/material/Divider";
import Loading from "./../../shared/Loading";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm, SubmitHandler } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// components

// service

export default function Form() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isStaff, setIsStaff] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const login = useLogin(setIsStaff);

  const onSubmit: SubmitHandler<Inputs> = (data_) => {
    login.mutate(data_);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toaster />
      <Card
        sx={{
          width: 500,
          height: 350,
          borderRadius: 5,
        }}
      >
        <CardHeader
          title={SINGIN}
          sx={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        />
        <Divider
          sx={{
            height: "1px",
            margin: "1rem 1rem",
          }}
        />
        <CardContent
          sx={{
            padding: "1rem 4rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <FormControl
                variant="outlined"
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {USERNAME}
                </InputLabel>
                <OutlinedInput
                  type="text"
                  label={USERNAME}
                  error={login.isError || errors.username ? true : false}
                  {...register("username", { required: true })}
                />
                {errors.username && (
                  <Typography
                    sx={{
                      color: "red",
                      marginTop: "0.5rem",
                      fontSize: "0.8rem",
                    }}
                  >
                    {REQUIRED_USERNAME}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <FormControl
                variant="outlined"
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {PASSWORD}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={PASSWORD}
                  error={login.isError || errors.password ? true : false}
                  {...register("password", { required: true })}
                />
              </FormControl>
              {errors.password && (
                <Typography
                  sx={{
                    color: "red",
                    marginTop: "0.5rem",
                    fontSize: "0.8rem",
                  }}
                >
                  {REQUIRED_PASSWORD}
                </Typography>
              )}

              {login.isError && (
                <Typography
                  sx={{
                    color: "red",
                    marginTop: "0.5rem",
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {
                    // @ts-ignore
                    login.error.response.status === 401
                      ? // @ts-ignore
                        login.error.response.data.detail
                      : { LOGIN_ERROR }
                  }
                </Typography>
              )}
            </Grid>
            {isStaff && (
              <Grid
                size={{
                  xs: 12,
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {NOT_ALLOWED_STAFF_CONNECTED}
                </Box>
              </Grid>
            )}
            <Grid
              size={{
                xs: 12,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{
                  height: "3rem",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
                disabled={
                  watch("username") === "" || watch("password") === ""
                    ? true
                    : false
                }
              >
                {login.isPending ? (
                  <Loading size={10} color="inherit" />
                ) : (
                  LOGIN
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
}
