import {
  BUY,
  SELL,
  ERROR,
  NO_DATA,
  TRANSFER,
  VIEW_ALL,
  FILTER_BY_DATE,
  SEARCH_BY_TXHASH,
  FILTER_BY_BLOCKCHAIN,
} from "../constantes/app";
import {
  useFilterTransactions,
  useSearchTransactions,
} from "../hook/useManageDataTransaction";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import color from "../constantes/color";
import Loading from "../shared/Loading";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import { selectUser } from "../redux/selector";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Transaction } from "../types/transaction";
import SearchIcon from "@mui/icons-material/Search";
import CardContent from "@mui/material/CardContent";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import TableContainer from "@mui/material/TableContainer";
import InputAdornment from "@mui/material/InputAdornment";
import { useManageUserIDs } from "../hook/useManageUserIDs";
import React, { useState, useEffect, useMemo } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useGetTransaction } from "../hook/useGetTransaction";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoTransaction from "../features/Transaction/InfoTransaction";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { DownloadLink } from "../features/Transaction/DownloadTransaction";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

export default function ManageTransaction() {
  const today = dayjs();

  const userName = useParams<{ userName: string }>().userName;

  const [dataFilterTransaction, setDataFilterTransaction] =
    useState<Transaction[]>();

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const userConnected = useSelector(selectUser).data;
  const userSelected = useGetUserConnectedOrSelected(userName);

  const userManagedIds = useManageUserIDs(userName);

  const transaction = useGetTransaction(userManagedIds);

  const {
    isFilterByBlockchain,
    blockChainSelect,
    setFilterItem,
    setIsFilterByBlockchain,
    setFilterByBlockchainValue,
    // Déstructurer les états et setters pour les dates
    startDate,
    setStartDate,
  } = useFilterTransactions(transaction.data, setDataFilterTransaction);

  const { isFiltering, isSearch, searchValue, setSearchValue, setIsSearch } =
    useSearchTransactions(transaction.data, setDataFilterTransaction);

  const uRI = process.env.REACT_APP_API_URL_BACKEND;

  const sortTransactions = (
    transactions: Transaction[],
    order: "asc" | "desc"
  ) => {
    return [...transactions].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (order === "asc") return dateA.getTime() - dateB.getTime();
      else return dateB.getTime() - dateA.getTime();
    });
  };

  const sortedData = useMemo(() => {
    if (!dataFilterTransaction) return [];
    return sortTransactions(dataFilterTransaction, sortOrder);
  }, [dataFilterTransaction, sortOrder]);

  useEffect(() => {
    // Vérifiez si sortedData a vraiment changé par rapport à l'état actuel
    if (
      dataFilterTransaction &&
      dataFilterTransaction.length > 0 &&
      !arraysAreEqual(dataFilterTransaction, sortedData) // Fonction de comparaison
    ) {
      setDataFilterTransaction(sortedData);
    }
  }, [sortedData, dataFilterTransaction]);

  // Fonction pour comparer deux tableaux (ou objets complexes)
  function arraysAreEqual(arr1: Transaction[], arr2: Transaction[]) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const RenderLoading = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <Loading color="primary" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Toaster />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card sx={{ borderRadius: 3 }}>
          <CardHeader
            title={`Transactions (${
              dataFilterTransaction ? dataFilterTransaction.length : 0
            })`}
            action={
              <>
                {!isSearch && !isFilterByBlockchain && (
                  <>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        setFilterItem("ALL");
                        setIsFilterByBlockchain(false);
                        setFilterByBlockchainValue("");
                        setIsSearch(false);
                        setSearchValue("");
                        setStartDate(null);
                      }}
                    >
                      {VIEW_ALL}
                    </Button>
                    <Button
                      size="small"
                      color="success"
                      variant="contained"
                      sx={{ marginRight: 1 }}
                      onClick={() => setFilterItem("BUY")}
                    >
                      {BUY}
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      sx={{ marginRight: 1 }}
                      onClick={() => setFilterItem("SELL")}
                    >
                      {SELL}
                    </Button>
                    <Button
                      size="small"
                      color="info"
                      variant="contained"
                      onClick={() => setFilterItem("TRANSFER")}
                    >
                      {TRANSFER}
                    </Button>
                  </>
                )}

                {isFilterByBlockchain && (
                  <Grid container spacing={2}>
                    <Grid
                      size={{
                        xs: 4.5,
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label={FILTER_BY_BLOCKCHAIN}
                        variant="outlined"
                        fullWidth
                        select
                        sx={{
                          marginRight: 1,
                          marginLeft: 1,
                          "& .MuiInputBase-root": {
                            height: "45px",
                          },
                        }}
                        onChange={(e) =>
                          setFilterByBlockchainValue(e.target.value)
                        }
                      >
                        {blockChainSelect.map((blockchain) => (
                          <MenuItem
                            key={blockchain.value}
                            value={blockchain.value}
                          >
                            {blockchain.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      size={{
                        xs: 4.5,
                      }}
                    >
                      <DatePicker
                        label={FILTER_BY_DATE}
                        value={startDate}
                        shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        slotProps={{
                          textField: {
                            sx: {
                              "& .MuiInputBase-root": {
                                height: "45px",
                              },
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      size={{
                        xs: 1.5,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsFilterByBlockchain(false);
                          setStartDate(null);
                        }}
                      >
                        <FilterListOffIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      size={{
                        xs: 1,
                      }}
                    >
                      <Tooltip
                        title={`Tri ${
                          sortOrder === "asc" ? "Descendant" : "Ascendant"
                        }`}
                      >
                        <IconButton
                          onClick={() =>
                            setSortOrder((prev) =>
                              prev === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          <SwapVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}

                {isSearch && (
                  <TextField
                    id="outlined-basic"
                    label={SEARCH_BY_TXHASH}
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={searchValue}
                    sx={{ marginRight: 1, width: 400 }}
                    slotProps={{
                      input: {
                        endAdornment: searchValue && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear search"
                              onClick={() => setSearchValue("")}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                )}

                {!isFilterByBlockchain && (
                  <Tooltip
                    title={`Sort ${
                      sortOrder === "asc" ? "Descending" : "Ascending"
                    }`}
                  >
                    <IconButton
                      onClick={() =>
                        setSortOrder((prev) =>
                          prev === "asc" ? "desc" : "asc"
                        )
                      }
                    >
                      <SwapVertIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {!isSearch && !isFilterByBlockchain && (
                  <IconButton>
                    <FilterListIcon
                      onClick={() => setIsFilterByBlockchain(true)}
                    />
                  </IconButton>
                )}
                {!isFilterByBlockchain && (
                  <IconButton onClick={() => setIsSearch((prev) => !prev)}>
                    {isSearch ? <SearchOffIcon /> : <SearchIcon />}
                  </IconButton>
                )}

                {!isFilterByBlockchain && (
                  <>
                    {((userSelected && Object.keys(userSelected).length > 0) ||
                      (userConnected && !userConnected.is_manager)) && (
                      <>
                        {userSelected &&
                        Object.keys(userSelected).length > 0 ? (
                          <DownloadLink
                            url={`${uRI}/excel-transactions/?user_id=${userSelected.id}`}
                            fileName={`transaction-${
                              userSelected.username
                            }-${new Date()
                              .toDateString()
                              .split(" ")
                              .join("-")}.xlsx`}
                          />
                        ) : (
                          <DownloadLink
                            url={`${uRI}/excel-transactions/?user_id=${userConnected?.id}`}
                            fileName={`transaction-${
                              userConnected?.username
                            }-${new Date()
                              .toDateString()
                              .split(" ")
                              .join("-")}.xlsx`}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            }
          />

          <Divider sx={{ margin: 2 }} />
          <CardContent sx={{ padding: 2, height: "80vh", overflow: "auto" }}>
            {transaction.isLoading ? (
              <RenderLoading />
            ) : transaction.isError ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: color.red,
                  fontWeight: "bold",
                }}
              >
                {ERROR} : {transaction.error.message}
              </Box>
            ) : transaction.data && transaction.data.length > 0 ? (
              dataFilterTransaction && dataFilterTransaction.length > 0 ? (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      {dataFilterTransaction!.map((el, index) => (
                        <InfoTransaction key={index} data={el} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : !isFiltering ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  {NO_DATA}
                </Box>
              ) : (
                <RenderLoading />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {NO_DATA}
              </Box>
            )}
          </CardContent>
        </Card>
      </LocalizationProvider>
    </>
  );
}
