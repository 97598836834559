import Card from "@mui/material/Card";
import React, { Suspense } from "react";
import Loading from "../shared/Loading";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ManagePriceCalculator from "../features/PriceCalculator";
export default function PriceCalculator() {
  return (
    <Card>
      <CardHeader
        title="Price Calculator"
        sx={{
          textAlign: "center",
        }}
      />
      <Divider variant="middle" />
      <CardContent
        sx={{
          padding: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Suspense fallback={<Loading />}>
          <ManagePriceCalculator />
        </Suspense>
      </CardContent>
    </Card>
  );
}
