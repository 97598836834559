import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import Card from "@mui/material/Card";
import { Asset } from "../../types/asset";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import Divider from "@mui/material/Divider";
import { Snapshot } from "../../types/snapshot";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import HighchartsReact from "highcharts-react-official";
import { getTextByLanguage } from "../../utils/function";
import Accessibility from "highcharts/modules/accessibility";
import { selectDisplayPortfolio } from "../../redux/selector";
import { ASSET_ALLOCATION, NO_DATA } from "../../constantes/app";

// Activer le module d'accessibilité
Accessibility(Highcharts);

type formatData = {
  name: string;
  y: number;
};

export default function AssetAllocation({
  asset,
  snapshotToday,
  isTranslation = false,
  isReport = false,
  language = "fr",
}: {
  asset?: UseQueryResult<Asset[], Error>;
  snapshotToday?: Snapshot[];
  isTranslation?: boolean;
  isReport?: boolean; // Pour activer le rapport sur les allocations
  language?: "fr" | "en";
}) {
  const [finalData, setFinalData] = useState<formatData[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  const displayPortFolio = useSelector(selectDisplayPortfolio);

  // Fonction pour grouper les données
  const groupData = (
    assetData: Asset[] | undefined,
    snapshotData: Snapshot[] | undefined,
    isReport: boolean,
    isPortfolioActive: boolean
  ): { [key: string]: number } => {
    const groupedData: { [key: string]: number } = {};

    if ((isReport || isPortfolioActive) && snapshotData) {
      snapshotData.forEach((snapshot) => {
        groupedData[snapshot.asset] =
          (groupedData[snapshot.asset] || 0) +
          parseFloat(snapshot.asset_net_worth);
      });
    } else if (assetData) {
      assetData.forEach((el) => {
        groupedData[el.symbol] =
          (groupedData[el.symbol] || 0) + parseFloat(el.net_worth);
      });
    }

    return groupedData;
  };

  // Fonction pour formater et trier les données
  const formatAndSortData = (groupedData: { [key: string]: number }) => {
    return Object.entries(groupedData)
      .map(([name, count]) => ({ name, y: count }))
      .sort((a, b) => b.y - a.y);
  };

  useEffect(() => {
    if (
      (asset?.data && asset.data.length > 0) ||
      (snapshotToday && snapshotToday.length > 0)
    ) {
      const groupedData = groupData(
        asset?.data,
        snapshotToday,
        isReport,
        displayPortFolio.isActive
      );

      const formattedData = formatAndSortData(groupedData);

      setFinalData(formattedData);
      setTotalData(formattedData.reduce((acc, item) => acc + item.y, 0));
    }
  }, [asset?.data, snapshotToday, displayPortFolio.isActive, isReport]);

  // Mémoïser les options du graphique pour éviter les re-rendus inutiles
  const options = useMemo(
    () => ({
      credits: {
        enabled: false,
      },
      chart: {
        type: "pie",
        height: 270,
      },
      title: {
        text: "",
      },
      tooltip: {
        // @ts-ignore
        formatter: function () {
          return (
            "<b>" +
            // @ts-ignore
            this.point.name +
            "</b><br/> ~ " +
            // @ts-ignore
            Highcharts.numberFormat(this.point.percentage, 1) +
            " %"
          );
        },
      },
      plotOptions: {
        pie: {
          animation: false, // Désactiver l'animation pour de meilleures performances
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
          showInLegend: true,
        },
      },
      legend: {
        align: "right", // Positionner la légende à droite
        verticalAlign: "middle", // Centrer verticalement
        layout: "vertical", // Disposer les éléments verticalement
        itemMarginBottom: 10, // Ajouter de l'espace entre les éléments
        symbolRadius: 0, // Forme carrée
        symbolHeight: 8,
        symbolWidth: 8, // @ts-ignore
        labelFormatter: function () {
          return (
            // @ts-ignore
            this.name +
            " : " +
            // @ts-ignore
            Highcharts.numberFormat((this.y / totalData) * 100, 1) +
            " %"
          );
        },
      },
      series: [
        {
          innerSize: "75%",
          data: finalData,
        },
      ],
    }),
    [finalData, totalData]
  );

  return (
    <Card
      sx={{
        borderRadius: "1rem",
      }}
    >
      <CardHeader
        sx={{
          textAlign: "center",
        }}
        title={
          isTranslation
            ? getTextByLanguage("ASSET_ALLOCATION", language)
            : ASSET_ALLOCATION
        }
      />
      <Divider variant="middle" />
      <CardContent sx={{ mt: -2, height: "22.75rem" }}>
        {asset?.isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Loading size={15} color="inherit" />
          </Box>
        ) : asset?.isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: color.red,
              fontWeight: "bold",
              fontSize: "1.2rem",
              textAlign: "center",
              height: "100%",
            }}
          >
            {asset.error.message}
          </Box>
        ) : (
          <>
            {(isReport || displayPortFolio.isActive) &&
            snapshotToday &&
            snapshotToday.length > 0 ? (
              // Priorité aux données de snapshotToday si isReport ou displayPortFolio est actif
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (!isReport &&
                !displayPortFolio.isActive &&
                asset?.data?.length! > 0) ||
              asset?.data?.length! > 0 ? (
              // Afficher les données de asset si snapshotToday est vide ou non pertinent
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              // Aucune donnée disponible
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                {NO_DATA}
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
