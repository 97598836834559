import {
  ERROR_DOWNLOAD,
  SUCCESS_DOWNLOAD,
  DOWNLOAD_XLSX_TRANSACTION,
} from "../../../constantes/app";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const DownloadLink = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string | undefined;
}) => {
  const handleDownload = () => {
    fetch(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        toast.success(SUCCESS_DOWNLOAD);
      })
      .catch((err) => {
        toast.error(`${ERROR_DOWNLOAD} : ${err.message}`);
      });
  };

  return (
    <Tooltip title={DOWNLOAD_XLSX_TRANSACTION}>
      <IconButton onClick={handleDownload}>
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
};
