import dayjs from "dayjs";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { SET_TIME_MACHINE } from "../../constantes/redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function ChooseDate({
  isActiveTimeMachine,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  validate,
  reset,
}: {
  isActiveTimeMachine: boolean;
  startDate: string | null;
  setStartDate: (date: string | null) => void;
  endDate: string | null;
  setEndDate: (date: string | null) => void;
  validate: () => void;
  reset: () => void;
}) {
  const dispatch = useDispatch();

  const today = dayjs();

  return (
    <Card>
      <CardHeader
        title="Choose dates"
        sx={{
          textAlign: "center",
        }}
      />
      <Divider variant="middle" />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "30px",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Choose start Date"
                shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                value={startDate ? new Date(startDate) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    try {
                      const formattedDate = format(newValue, "yyyy-MM-dd");
                      setStartDate(formattedDate);
                      dispatch({
                        type: SET_TIME_MACHINE,
                        payload: { startDate: formattedDate },
                      });
                    } catch (error) {
                      console.error("Invalid date:", error);
                    }
                  } else {
                    console.warn("Date is invalid or null");
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Choose end Date"
                shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                value={endDate ? new Date(endDate) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    try {
                      const formattedDate = format(newValue, "yyyy-MM-dd");
                      setEndDate(formattedDate);
                      dispatch({
                        type: SET_TIME_MACHINE,
                        payload: { endDate: formattedDate },
                      });
                    } catch (error) {
                      console.error("Invalid date:", error);
                    }
                  } else {
                    console.warn("Date is invalid or null");
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          {!isActiveTimeMachine ? (
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
              textAlign="center"
            >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: "3rem",
                  fontSize: "1.2rem",
                }}
                onClick={validate}
              >
                Validate
              </Button>
            </Grid>
          ) : (
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
              textAlign="center"
            >
              <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{
                  height: "3rem",
                  fontSize: "1.2rem",
                }}
                onClick={reset}
              >
                Reset
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
