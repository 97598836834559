import Box  from "@mui/material/Box";
import Login from "./../features/Login";
import curved from "../assets/images/curved1.jpg";

export default function LoginView() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          margin: "auto",
        }}
      >
        <Login />
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${curved})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "50%",
        }}
      ></Box>
    </Box>
  );
}
