import {
  SAVE,
  NAME,
  TX_PNL,
  CANCEL,
  TX_AMOUNT,
  TOKEN_SOLD,
  EXIT_PRICE,
  EXIT_PLAN_FOR,
  REMAINING_TOKENS,
  PERCENT_DISTRIBUTION,
  COULD_NOT_RESOLVE_DATA,
  ERROR_PERCENTAGE_DISTRIBUTION,
} from "../../../constantes/app";
import {
  selectLivePrice,
  selectAssetData,
  selectEuroDollar,
} from "../../../redux/selector";
import {
  calculateExitPlanCumPnLRate,
  calculateExitPlanCumPnLValue,
} from "../calcul";
import {
  calculateDollarEuro,
  calculateEuroDollar,
} from "../../../utils/calcul";
import Swal from "sweetalert2";
import ChartDetails from "./Chart";
import Box from "@mui/material/Box";
import { TTakeProfit } from "./type";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import HeaderExitPlan from "../Header";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import Loading from "../../../shared/Loading";
import color from "../../../constantes/color";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import { useGetAsset } from "../../../hook/useGetAsset";
import withReactContent from "sweetalert2-react-content";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate, useParams } from "react-router-dom";
import { EXIT_PLAN_ROUTE } from "../../../constantes/routes";
import CustomTableCell from "../../../shared/CustomTableCell";
import { useGetLogoCrypto } from "../../../hook/useGetLogoCrypto";
import { useGetExitPlanById } from "../../../hook/useGetExitPlan";
import { useCalculateAvgAcquisitionCost, useUpdateExitPlan } from "../hooks";
import { useManageUserSelectedID } from "../../../hook/useManageUserSelectedID";

export default function DetailsExitPlan() {
  const livePrice = useSelector(selectLivePrice);
  const euroDollar = useSelector(selectEuroDollar);
  const userName = useParams<{ userName: string }>().userName;
  const userId = useManageUserSelectedID(userName);
  const exitPlanID = useParams<{ id: string }>().id;
  const ExitPlanDetails = useGetExitPlanById(parseInt(exitPlanID!));
  const logo = useGetLogoCrypto(ExitPlanDetails.data?.symbol!);
  const asset = useGetAsset(`${userId}`, ExitPlanDetails.data?.symbol!);
  const { avgAcquisitionCost, allTimeSold, avgSellingPrice } =
    useCalculateAvgAcquisitionCost(asset);
  const assetData = useSelector(selectAssetData).data?.filter(
    (item) => item.asset === ExitPlanDetails.data?.symbol!
  )[0];

  const [takeProfit, setTakeProfit] = useState<TTakeProfit[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [finalData, setFinalData] = useState<
    {
      tp_price: number;
      distribution_percent: number;
    }[]
  >([]);
  const [isError, setIsError] = useState(false);

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      ExitPlanDetails.data &&
      ExitPlanDetails.data.take_profits.length > 0 &&
      assetData
    ) {
      const takeProfits: TTakeProfit[] = [];
      ExitPlanDetails.data.take_profits.forEach((item) => {
        const token_sold =
          (parseFloat(assetData!.owned_assets!) * item.distribution_percent) /
          100;
        takeProfits.push({
          tp_price: item.tp_price,
          distribution_percent: item.distribution_percent,
          token_sold: token_sold,
          tx_amount: item.tp_price * token_sold,
          tx_pnl:
            (((item.tp_price - avgAcquisitionCost!) *
              item.distribution_percent) /
              100) *
            parseFloat(assetData!.owned_assets!),
        });
      });
      setTakeProfit(takeProfits);
    }
  }, [ExitPlanDetails.data, assetData, avgAcquisitionCost]);

  const handleDelete = (index: number) => {
    setTakeProfit((prev) => prev.filter((_, i) => i !== index));
    toast.success(
      `You removed your ${
        index + 1
      } take profit. Don't forget to save your changes!`
    );
    setHasChanges(true);
  };

  const handleAdd = (index: number) => {
    if (takeProfit.length === 0) {
      setTakeProfit([
        {
          tp_price: 0,
          distribution_percent: 0,
          token_sold: 0,
          tx_amount: 0,
          tx_pnl: 0,
        },
      ]);
      return;
    }
    setTakeProfit((prev) => {
      const updated = [
        ...prev.slice(0, index + 1),
        {
          tp_price: 0,
          distribution_percent: 0,
          token_sold: 0,
          tx_amount: 0,
          tx_pnl: 0,
        },
        ...prev.slice(index + 1),
      ];
      setHasChanges(true);
      return updated;
    });
  };

  const handleChange = (
    index: number,
    key: keyof TTakeProfit,
    value: number
  ) => {
    setTakeProfit((prev) => {
      const updated = [...prev];

      if (key === "tp_price") {
        // Convert value to dollars if the current currency is not dollars
        const valueInDollar =
          euroDollar.currency === "$"
            ? value
            : calculateDollarEuro(value, euroDollar.valueEuroDollar);
        updated[index][key] = valueInDollar;
      } else {
        updated[index][key] = value;
      }
      // Recalculate the dependent values
      const token_sold =
        (parseFloat(assetData!.owned_assets!) *
          updated[index].distribution_percent) /
        100;
      updated[index].token_sold = token_sold;
      updated[index].tx_amount = updated[index].tp_price * token_sold;
      updated[index].tx_pnl =
        (((updated[index].tp_price - avgAcquisitionCost!) *
          updated[index].distribution_percent) /
          100) *
        parseFloat(assetData!.owned_assets!);
      setHasChanges(true);
      if (
        updated.reduce((acc, curr) => acc + curr.distribution_percent, 0) > 100
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }

      return updated;
    });
  };

  useEffect(() => {
    setFinalData(
      takeProfit.map((item, index) => ({
        tp_price: item.tp_price,
        distribution_percent: item.distribution_percent,
      }))
    );
  }, [takeProfit]);

  const updateExitPlan = useUpdateExitPlan(parseInt(exitPlanID!));

  const finalPnLValue = calculateExitPlanCumPnLValue(
    avgAcquisitionCost!,
    avgSellingPrice!,
    allTimeSold!,
    takeProfit
  );

  const finalPnLRate =
    calculateExitPlanCumPnLRate(
      avgAcquisitionCost!,
      avgSellingPrice!,
      allTimeSold!,
      takeProfit
    ) * 100;

  const totalPercentDistribution = takeProfit.reduce(
    (acc, curr) =>
      !isNaN(curr.distribution_percent) ? acc + curr.distribution_percent : acc,
    0
  );

  return (
    <>
      <Toaster />
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
          }}
        >
          {(!assetData && takeProfit.length > 0) || asset.isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                margin: "10px 280px",
              }}
            >
              <Loading color="inherit" />
            </Box>
          ) : Object.keys(assetData || {}).length > 0 ? (
            <HeaderExitPlan
              owned={assetData?.owned_assets!}
              currentPnlValue={assetData?.portfolio_unrealized_cum_pnl!}
              currentPnlPercent={assetData?.portfolio_unrealized_cum_pnl_rate!}
              currentPrice={livePrice[ExitPlanDetails.data?.symbol!]}
              avgAcquisitionConst={avgAcquisitionCost}
            />
          ) : (
            <HeaderExitPlan NoData={true} />
          )}
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 8,
            xl: 8,
          }}
        >
          <Card>
            <CardContent>
              {ExitPlanDetails.isLoading || asset.isLoading ? (
                <Box sx={{ margin: "10px 280px" }}>
                  <Loading color="inherit" />
                </Box>
              ) : (
                <Grid container spacing={3}>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        component="div" // Rend en <div> au lieu de <p>
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {EXIT_PLAN_FOR} {ExitPlanDetails.data?.symbol}{" "}
                        <>
                          {logo.isLoading ? (
                            <Loading color="inherit" />
                          ) : (
                            <Avatar
                              src={logo.data?.logo}
                              sx={{
                                width: 30,
                                height: 30,
                                borderRadius: 50,
                                borderWidth: 2,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6">
                        {NAME} :{ExitPlanDetails.data?.name}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <CustomTableCell align="center">
                              {EXIT_PRICE}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              <p
                                style={{
                                  lineHeight: "5px",
                                }}
                              >
                                {PERCENT_DISTRIBUTION}
                              </p>
                              {ExitPlanDetails.data!.take_profits.length > 0 &&
                                assetData &&
                                takeProfit.length > 0 && (
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      lineHeight: "11px",
                                    }}
                                  >
                                    Total : {totalPercentDistribution} %
                                  </p>
                                )}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              <p
                                style={{
                                  lineHeight: "0.5px",
                                }}
                              >
                                {TOKEN_SOLD}
                              </p>
                              {ExitPlanDetails.data!.take_profits.length > 0 &&
                                takeProfit.length > 0 &&
                                assetData && (
                                  <p
                                    style={{
                                      lineHeight: "0,5px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Total :{" "}
                                    {takeProfit
                                      .reduce(
                                        (acc, curr) =>
                                          !isNaN(curr.token_sold)
                                            ? acc + curr.token_sold
                                            : acc,
                                        0
                                      )
                                      .toFixed(5)}{" "}
                                    <br />
                                    Remaining :{" "}
                                    {(
                                      parseFloat(assetData.owned_assets!) -
                                      takeProfit.reduce(
                                        (acc, curr) =>
                                          !isNaN(curr.token_sold)
                                            ? acc + curr.token_sold
                                            : acc,
                                        0
                                      )
                                    ).toFixed(5)}{" "}
                                  </p>
                                )}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              {TX_AMOUNT}
                              {`(${euroDollar.currency})`}
                            </CustomTableCell>
                            <CustomTableCell align="center">
                              {TX_PNL}
                              {`(${euroDollar.currency})`}
                            </CustomTableCell>
                            <CustomTableCell align="center"></CustomTableCell>
                          </TableRow>
                        </TableHead>
                        {takeProfit.length > 0 ? (
                          <>
                            <TableBody>
                              {takeProfit.map((tk, index) => (
                                <TableRow key={index}>
                                  <TableCell align="center">
                                    <TextField
                                      sx={{ width: "150px" }}
                                      type="number"
                                      value={
                                        euroDollar.currency === "$"
                                          ? tk.tp_price
                                          : calculateEuroDollar(
                                              tk.tp_price,
                                              euroDollar.currency,
                                              euroDollar.valueEuroDollar
                                            )
                                      }
                                      slotProps={{
                                        input: {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {euroDollar.currency}
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "tp_price",
                                          parseFloat(e.target.value)
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <TextField
                                      sx={{ width: "100px" }}
                                      type="number"
                                      value={tk.distribution_percent}
                                      error={isError}
                                      helperText={
                                        isError && ERROR_PERCENTAGE_DISTRIBUTION
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "distribution_percent",
                                          parseFloat(e.target.value)
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    {!isNaN(tk.token_sold)
                                      ? tk.token_sold.toFixed(5)
                                      : 0}
                                  </TableCell>
                                  <TableCell align="center">
                                    <>
                                      {isNaN(tk.tx_amount) ? (
                                        0
                                      ) : (
                                        <>
                                          {calculateEuroDollar(
                                            tk.tx_amount,
                                            euroDollar.currency,
                                            euroDollar.valueEuroDollar
                                          ).toFixed(2)}
                                        </>
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color:
                                        tk.tx_pnl > 0 ? color.green : color.red,
                                    }}
                                  >
                                    <>
                                      {isNaN(tk.tx_pnl) ? (
                                        0
                                      ) : (
                                        <>
                                          {calculateEuroDollar(
                                            tk.tx_pnl,
                                            euroDollar.currency,
                                            euroDollar.valueEuroDollar
                                          ).toFixed(2)}
                                        </>
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      onClick={() => handleDelete(index)}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleAdd(index)}
                                      disabled={
                                        takeProfit.reduce(
                                          (acc, curr) =>
                                            acc + curr.distribution_percent,
                                          0
                                        ) >= 100
                                      }
                                    >
                                      <AddCircleIcon
                                        sx={{
                                          color:
                                            takeProfit.reduce(
                                              (acc, curr) =>
                                                acc + curr.distribution_percent,
                                              0
                                            ) >= 100
                                              ? "grey"
                                              : color.bluePrimary,
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <Typography style={{ margin: "10px 10px" }}>
                              Final PnL:{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    finalPnLValue > 0 ? color.green : color.red,
                                }}
                              >
                                {calculateEuroDollar(
                                  finalPnLValue,
                                  euroDollar.currency,
                                  euroDollar.valueEuroDollar
                                ).toFixed(2)}
                                {`${euroDollar.currency}`}
                              </span>{" "}
                              |{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    finalPnLRate > 0 ? color.green : color.red,
                                }}
                              >
                                {finalPnLRate.toFixed(2)}%
                              </span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            {!assetData && (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={6}>
                                    <Box
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "1.0rem",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        color: color.red,
                                      }}
                                    >
                                      {COULD_NOT_RESOLVE_DATA}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </>
                        )}
                        {assetData && (
                          <>
                            {takeProfit.length === 0 &&
                            ExitPlanDetails.data!.take_profits.length === 0 ? (
                              <IconButton onClick={() => handleAdd(0)}>
                                <AddCircleIcon
                                  color="primary"
                                  fontSize="large"
                                />
                              </IconButton>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={!hasChanges || isError}
                                  sx={{ margin: "10px 10px ", width: "100px" }}
                                  onClick={() => {
                                    MySwal.fire({
                                      title: "Are you sure?",
                                      text: "You want to save your changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, save changes!",
                                      reverseButtons: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setHasChanges(false);
                                        updateExitPlan.mutate({
                                          id: parseFloat(exitPlanID!),
                                          take_profits: finalData,
                                        });
                                      }
                                    });
                                  }}
                                >
                                  {SAVE}
                                </Button>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    margin: "10px 10px ",
                                    width: "100px",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      userName
                                        ? `/${EXIT_PLAN_ROUTE}/${userName}`
                                        : `/${EXIT_PLAN_ROUTE}`
                                    )
                                  }
                                >
                                  {CANCEL}
                                </Button>
                              </Box>
                            )}
                          </>
                        )}
                      </Table>
                    </TableContainer>
                    {takeProfit.length === 0 &&
                      ExitPlanDetails.data!.take_profits.length === 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            margin: "10px 10px ",
                            width: "100px",
                          }}
                          onClick={() =>
                            navigate(
                              userName
                                ? `/${EXIT_PLAN_ROUTE}/${userName}`
                                : `/${EXIT_PLAN_ROUTE}`
                            )
                          }
                        >
                          {CANCEL}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        {assetData && takeProfit.length > 0 && (
          <Grid
            size={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 4,
              xl: 4,
            }}
          >
            <Card>
              <CardHeader
                title={`${REMAINING_TOKENS} : ${parseFloat(
                  assetData?.owned_assets!
                ).toFixed(2)}`}
                sx={{ textAlign: "center" }}
              />
              <CardContent>
                <ChartDetails
                  owned={parseFloat(
                    parseFloat(assetData?.owned_assets!).toFixed(2)
                  )}
                  percentDistribution={
                    takeProfit.reduce(
                      (acc, curr) => acc + curr.distribution_percent,
                      0
                    ) || 0
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}
