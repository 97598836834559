import { SelectInput } from "../types/index";

export const ARRAY_SYMBOL: SelectInput[] = [
  { value: "BTC", label: "BTC" },
  { value: "ETH", label: "ETH" },
  { value: "LTC", label: "LTC" },
  { value: "MATIC", label: "MATIC" },
  { value: "USDT", label: "USDT" },
];

export const ARRAY_BLOCKCHAIN: SelectInput[] = [
  { value: "BITCOIN", label: "BITCOIN" },
  { value: "ETHEREUM", label: "ETHEREUM" },
  // { value: "TEZOS", label: "TEZOS" },
];

export const ARRAY_STORAGE_TYPE = [
  { value: "CEX", label: "CEX" },
  { value: "DEX", label: "DEX" },
  { value: "HOT_WALLET", label: "HOT_WALLET" },
  { value: "COLD_WALLET", label: "COLD_WALLET" },
];
