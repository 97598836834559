import {
  MANAGE_USER,
  NO_USER_FOUND,
  SELECTED_USER,
  SEARCH_BY_FIRSTNAME_OR_LASTNAME,
} from "../constantes/app";
import Box from "@mui/material/Box";
import { User } from "../types/user";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Loading from "../shared/Loading";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { selectUser } from "../redux/selector";
import TextField from "@mui/material/TextField";
import { useGetUsers } from "../hook/useGetUser";
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import { useSearchUser } from "../hook/useSearchUser";
import InputAdornment from "@mui/material/InputAdornment";
import CardManagedUser from "../features/ManagedUser/CardManagedUser";

export default function ManagedUser() {
  const [dataSearch, setDataSearch] = useState<User[] | undefined>();
  const [searchValue, setSearchValue] = useState<string>("");

  const userConnected = useSelector(selectUser).data;
  const users = useGetUsers(userConnected!.id);
  const searchUser = useSearchUser(searchValue);

  useEffect(() => {
    if (searchValue && searchUser.isSuccess && searchUser.data) {
      setDataSearch(searchUser.data);
    } else if (!searchValue && users.isSuccess && users.data) {
      setDataSearch(users.data);
    }
  }, [
    searchValue,
    searchUser.isSuccess,
    searchUser.data,
    users.isSuccess,
    users.data,
  ]);

  const renderContent = () => {
    if (users.isLoading || searchUser.isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Loading size={15} color="inherit" />
        </Box>
      );
    }

    if (users.isError || searchUser.isError) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            color: "red",
          }}
        >
          {users.error?.message || searchUser.error?.message}
        </Box>
      );
    }

    if (dataSearch && dataSearch.length > 0) {
      return (
        <Grid container spacing={2}>
          {dataSearch.map((user: User) => (
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
              }}
              key={user.id}
            >
              <CardManagedUser user={user} />
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "1.5rem",
            marginTop: "2rem",
            Alignment: "center",
          }}
        >
          {NO_USER_FOUND}
        </Box>
      </Grid>
    );
  };

  return (
    <Card>
      <CardHeader
        title={MANAGE_USER}
        action={
          <TextField
            id="outlined-basic"
            label={SEARCH_BY_FIRSTNAME_OR_LASTNAME}
            variant="outlined"
            value={searchValue}
            fullWidth
            size="small"
            sx={{ marginRight: 1, width: 300 }}
            slotProps={{
              input: {
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchValue("")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <Typography sx={{ marginBottom: 3, marginTop: -1 }}>
          {SELECTED_USER} {`(${dataSearch?.length})`}
        </Typography>
        {renderContent()}
      </CardContent>
    </Card>
  );
}
