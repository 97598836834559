import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import Loading from "../../../shared/Loading";
import TextField from "@mui/material/TextField";
import  MenuItem  from "@mui/material/MenuItem";
import { Snapshot } from "../../../types/snapshot";
import DialogTitle from "@mui/material/DialogTitle";
import { UseQueryResult } from "@tanstack/react-query";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateExitPlan, useUniqueAssetSymbol } from "./hook";
import { ADD_EXIT_PLAN, CANCEL, CREATE } from "../../../constantes/app";

type TInputCreateExitPlan = {
  userId: number;
  name: string;
  symbol: string;
};

export default function AddExitPlan({
  open,
  setOpen,
  asset,
  userId,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  asset: UseQueryResult<Snapshot[], Error>;
  userId: number;
}) {
  const userName = useParams<{ userName: string }>().userName;

  const handleClose = () => {
    setOpen(false);
  };
  const uniqueAssetSymbol = useUniqueAssetSymbol(asset);
  const createExitPlan = useCreateExitPlan();

  const { register, handleSubmit, watch, reset } =
    useForm<TInputCreateExitPlan>({
      defaultValues: {
        userId: userId,
        symbol: "",
        name: "",
      },
    });

  const navigate = useNavigate();

  const onSubmit = (data: TInputCreateExitPlan) => {
    createExitPlan.mutate(data, {
      onSuccess: (data) => {
        setOpen(false);
        reset();
        navigate(`/exit-plan/details/${userName}/${data.data.id}`, {
          replace: true,
        });
      },
    });
  };

  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <>
      <Toaster />
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={handleBackdropClick}
        fullWidth={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle fontWeight="bold" textAlign="center">
            {ADD_EXIT_PLAN}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              {...register("name", { required: true })}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              label="Asset"
              fullWidth
              variant="outlined"
              select
              {...register("symbol", { required: true })}
            >
              {uniqueAssetSymbol.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">
              {CANCEL}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                createExitPlan.isPending ||
                watch("symbol") === "" ||
                watch("name") === ""
              }
            >
              {createExitPlan.isPending ? (
                <Loading size={10} color="inherit" />
              ) : (
                CREATE
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
