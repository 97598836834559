import {
  PL,
  NO_DATA,
  NET_WORTH,
  INVESTMENT,
  FIAT_BALANCE,
  TOKEN_BALANCE,
  PORTFOLIO_BALANCE,
  STABLE_COIN_BALANCE,
} from "../../constantes/app";
import {
  selectLivePrice,
  selectEuroDollar,
  selectTimeMachine,
  selectDisplayPortfolio,
} from "../../redux/selector";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import PriceHelper from "../../shared/PriceHelper";
import CardContent from "@mui/material/CardContent";
import { UseQueryResult } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { SET_ASSET_DATA } from "../../constantes/redux";
import { calculateEuroDollar } from "../../utils/calcul";
import { getTextByLanguage } from "../../utils/function";
import TableContainer from "@mui/material/TableContainer";
import { PortofolioSnapshot, Snapshot } from "../../types/snapshot";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { AggregatedData, AggregatedDataSnapshot } from "../../types/index";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function OverView({
  snapshotToday,
  snapshot,
  selectData,
  setSelectData,
  title,
  setTitle,
  dataPortfolio,
  portfolioSnapshot,
  overview_only = false,
  isTranslation = false,
  language = "fr",
  isReportLastDate = false,
}: {
  snapshotToday: Snapshot[] | undefined;
  snapshot: UseQueryResult<Snapshot[], Error>;
  selectData: AggregatedDataSnapshot | undefined;
  setSelectData: React.Dispatch<
    React.SetStateAction<AggregatedDataSnapshot | undefined>
  >;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  dataPortfolio: PortofolioSnapshot[] | undefined;
  portfolioSnapshot: UseQueryResult<PortofolioSnapshot[], Error>;
  overview_only?: boolean;
  isTranslation?: boolean;
  language?: "fr" | "en";
  isReportLastDate?: boolean;
}) {
  // State
  const [currentSelected, setCurrentSelected] = useState<number>(0);
  const [overviewData, setOverview] = useState<AggregatedData>();
  const [assetData, setAssetData] = useState<AggregatedDataSnapshot[]>();
  const [newOverviewData, setNewOverviewData] = useState<AggregatedData>();

  // Selector
  const euroDollar = useSelector(selectEuroDollar);
  const livePrice = useSelector(selectLivePrice);
  const dispatch = useDispatch();

  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const timeMachine = useSelector(selectTimeMachine);

  useEffect(() => {
    if (snapshotToday && snapshotToday.length > 0) {
      const groupedDataAsset = snapshotToday.reduce(
        (acc: AggregatedDataSnapshot[], curr: Snapshot) => {
          const existingAssetIndex = acc.findIndex(
            (item) => item.asset === curr.asset
          );

          const safeValue = (value: any) => (!isFinite(value) ? 0 : value);

          if (existingAssetIndex !== -1) {
            acc[existingAssetIndex].crypto_net_worth = safeValue(
              parseFloat(acc[existingAssetIndex].crypto_net_worth) +
                (displayPortFolio.isActive ||
                timeMachine.isActive ||
                isReportLastDate
                  ? parseFloat(curr.asset_net_worth)
                  : livePrice && livePrice[curr.asset]
                  ? livePrice[curr.asset] * parseFloat(curr.owned_assets)
                  : 0)
            ).toFixed(2);

            acc[existingAssetIndex].portfolio_investment = safeValue(
              parseFloat(acc[existingAssetIndex].portfolio_investment) +
                (parseFloat(curr.avg_purchase_price) > 0 &&
                parseFloat(curr.all_time_purchased) > 0
                  ? parseFloat(curr.owned_assets) *
                    (parseFloat(curr.avg_purchase_price) +
                      parseFloat(curr.total_fees) /
                        parseFloat(curr.all_time_purchased))
                  : 0)
            ).toFixed(2);

            acc[existingAssetIndex].portfolio_unrealized_cum_pnl = safeValue(
              parseFloat(acc[existingAssetIndex].portfolio_unrealized_cum_pnl) +
                (displayPortFolio.isActive ||
                timeMachine.isActive ||
                isReportLastDate
                  ? parseFloat(curr.unrealized_cum_pnl)
                  : livePrice &&
                    livePrice[curr.asset] &&
                    parseFloat(curr.avg_purchase_price) > 0
                  ? (livePrice[curr.asset] -
                      parseFloat(curr.avg_purchase_price) -
                      parseFloat(curr.total_fees) /
                        parseFloat(curr.all_time_purchased)) *
                    parseFloat(curr.owned_assets)
                  : parseFloat(curr.unrealized_cum_pnl))
            ).toFixed(2);

            acc[existingAssetIndex].portfolio_unrealized_cum_pnl_rate =
              safeValue(
                parseFloat(
                  acc[existingAssetIndex].portfolio_unrealized_cum_pnl_rate
                ) +
                  (displayPortFolio.isActive ||
                  timeMachine.isActive ||
                  isReportLastDate
                    ? parseFloat(curr.unrealized_cum_pnl_rate) * 100
                    : livePrice &&
                      livePrice[curr.asset] &&
                      parseFloat(curr.avg_purchase_price) > 0
                    ? (livePrice[curr.asset] /
                        (parseFloat(curr.avg_purchase_price) +
                          parseFloat(curr.total_fees) /
                            parseFloat(curr.all_time_purchased)) -
                        1) *
                      100
                    : 0)
              ).toFixed(2);

            acc[existingAssetIndex].owned_assets = safeValue(
              parseFloat(acc[existingAssetIndex].owned_assets || "0") +
                parseFloat(curr.owned_assets)
            ).toString();

            acc[existingAssetIndex].avg_purchase_price = safeValue(
              parseFloat(acc[existingAssetIndex].avg_purchase_price!) +
                parseFloat(curr.avg_purchase_price)
            ).toString();
          } else {
            acc.push({
              asset: curr.asset,

              crypto_net_worth: safeValue(
                displayPortFolio.isActive ||
                  timeMachine.isActive ||
                  isReportLastDate
                  ? parseFloat(curr.asset_net_worth)
                  : livePrice && livePrice[curr.asset]
                  ? livePrice[curr.asset] * parseFloat(curr.owned_assets)
                  : 0
              ).toFixed(2),

              portfolio_investment: safeValue(
                parseFloat(curr.avg_purchase_price) > 0 &&
                  parseFloat(curr.all_time_purchased) > 0
                  ? parseFloat(curr.owned_assets) *
                      (parseFloat(curr.avg_purchase_price) +
                        parseFloat(curr.total_fees) /
                          parseFloat(curr.all_time_purchased))
                  : 0
              ).toFixed(2),

              portfolio_unrealized_cum_pnl: safeValue(
                displayPortFolio.isActive ||
                  timeMachine.isActive ||
                  isReportLastDate
                  ? parseFloat(curr.unrealized_cum_pnl)
                  : livePrice &&
                    livePrice[curr.asset] &&
                    parseFloat(curr.avg_purchase_price) > 0
                  ? (livePrice[curr.asset] -
                      parseFloat(curr.avg_purchase_price) -
                      parseFloat(curr.total_fees) /
                        parseFloat(curr.all_time_purchased)) *
                    parseFloat(curr.owned_assets)
                  : 0
              ).toFixed(2),

              portfolio_unrealized_cum_pnl_rate: safeValue(
                displayPortFolio.isActive ||
                  timeMachine.isActive ||
                  isReportLastDate
                  ? parseFloat(curr.unrealized_cum_pnl_rate) * 100
                  : livePrice &&
                    livePrice[curr.asset] &&
                    parseFloat(curr.avg_purchase_price) > 0
                  ? (livePrice[curr.asset] /
                      (parseFloat(curr.avg_purchase_price) +
                        parseFloat(curr.total_fees) /
                          parseFloat(curr.all_time_purchased)) -
                      1) *
                    100
                  : 0
              ).toFixed(2),

              owned_assets: curr.owned_assets,

              avg_purchase_price: curr.avg_purchase_price,
            });
          }
          return acc;
        },
        []
      );

      // Range date by crypto_net_worth value desc
      groupedDataAsset.sort((a, b) => {
        return parseFloat(b.crypto_net_worth) - parseFloat(a.crypto_net_worth);
      });

      let totalCryptoNetWorth = 0;
      let totalPortfolioInvestment = 0;
      let totalPortfolioUnrealizedCumPnl = 0;
      let totalPortfolioUnrealizedCumPnlRate = 0;

      groupedDataAsset.forEach((item) => {
        totalCryptoNetWorth += parseFloat(item.crypto_net_worth);
        totalPortfolioInvestment += parseFloat(item.portfolio_investment);
        totalPortfolioUnrealizedCumPnl += parseFloat(
          item.portfolio_unrealized_cum_pnl
        );
        totalPortfolioUnrealizedCumPnlRate += parseFloat(
          item.portfolio_unrealized_cum_pnl_rate
        );
      });

      setNewOverviewData({
        crypto_net_worth: totalCryptoNetWorth.toFixed(2),
        portfolio_investment: totalPortfolioInvestment.toFixed(2),
        portfolio_unrealized_cum_pnl: totalPortfolioUnrealizedCumPnl.toFixed(2),
        portfolio_unrealized_cum_pnl_rate:
          totalPortfolioUnrealizedCumPnlRate.toFixed(2),
      });

      setSelectData({
        asset: "Overview",
        crypto_net_worth: totalCryptoNetWorth.toFixed(2),
        portfolio_investment: totalPortfolioInvestment.toFixed(2),
        portfolio_unrealized_cum_pnl: totalPortfolioUnrealizedCumPnl.toFixed(2),
        portfolio_unrealized_cum_pnl_rate:
          totalPortfolioUnrealizedCumPnlRate.toFixed(2),
      });
      dispatch({
        type: SET_ASSET_DATA,
        payload: { data: groupedDataAsset },
      });
      setAssetData(groupedDataAsset);
    } else {
      setSelectData(undefined);
      dispatch({
        type: SET_ASSET_DATA,
        payload: { data: null },
      });
    }
  }, [
    snapshotToday,
    livePrice,
    setSelectData,
    dispatch,
    displayPortFolio.isActive,
    timeMachine.isActive,
    isReportLastDate,
  ]);

  useEffect(() => {
    if (dataPortfolio) {
      if (dataPortfolio.length > 1) {
        const countData: AggregatedData = dataPortfolio.reduce(
          (acc: AggregatedData, curr: PortofolioSnapshot) => {
            return {
              portfolio_fiat_balance: (
                parseFloat(acc.portfolio_fiat_balance!) +
                parseFloat(curr.fiat_balance)
              ).toFixed(2),
              portfolio_stable_coin_balance: (
                parseFloat(acc.portfolio_stable_coin_balance!) +
                parseFloat(curr.stable_balance)
              ).toFixed(2),
            };
          },
          {
            portfolio_fiat_balance: "0",
            portfolio_stable_coin_balance: "0",
          } as AggregatedData
        );
        setOverview(countData);
        if (selectData && selectData.asset === "Overview") {
          setSelectData({
            asset: "Overview",
            crypto_net_worth: newOverviewData?.crypto_net_worth!,
            portfolio_investment: newOverviewData?.portfolio_investment!,
            portfolio_unrealized_cum_pnl:
              newOverviewData?.portfolio_unrealized_cum_pnl!,
            portfolio_unrealized_cum_pnl_rate:
              newOverviewData?.portfolio_unrealized_cum_pnl_rate!,
            portfolio_fiat_balance: countData.portfolio_fiat_balance,
            portfolio_stable_coin_balance:
              countData.portfolio_stable_coin_balance,
          });
        }
      } else if (dataPortfolio.length === 1) {
        setOverview({
          portfolio_fiat_balance: parseFloat(
            dataPortfolio[0].fiat_balance
          ).toFixed(2),
          portfolio_stable_coin_balance: parseFloat(
            dataPortfolio[0].stable_balance
          ).toFixed(2),
        });
        if (selectData?.asset === "Overview") {
          setSelectData({
            asset: "Overview",
            crypto_net_worth: newOverviewData?.crypto_net_worth!,
            portfolio_investment: newOverviewData?.portfolio_investment!,
            portfolio_unrealized_cum_pnl:
              newOverviewData?.portfolio_unrealized_cum_pnl!,
            portfolio_unrealized_cum_pnl_rate:
              newOverviewData?.portfolio_unrealized_cum_pnl_rate!,
            portfolio_fiat_balance: parseFloat(
              dataPortfolio[0].fiat_balance
            ).toFixed(2),
            portfolio_stable_coin_balance: parseFloat(
              dataPortfolio[0].stable_balance
            ).toFixed(2),
          });
        }
      } else {
        setOverview({});
      }
    }
  }, [
    dataPortfolio,
    setSelectData,
    newOverviewData,
    selectData?.asset,
    selectData,
  ]);

  useEffect(() => {
    if (currentSelected !== 0 && assetData) {
      setTitle(assetData[currentSelected > 0 ? currentSelected - 1 : 0].asset);
      setSelectData(assetData[currentSelected > 0 ? currentSelected - 1 : 0]);
    } else {
      setTitle("overview");
    }
  }, [currentSelected, assetData, setSelectData, setTitle]);

  const nextSlide = () => {
    if (currentSelected < assetData!.length) {
      setCurrentSelected(currentSelected + 1);
    } else {
      setCurrentSelected(0);
      setTitle("overview");
      setSelectData({
        asset: "Overview",
        crypto_net_worth: newOverviewData?.crypto_net_worth!,
        portfolio_investment: newOverviewData?.portfolio_investment!,
        portfolio_unrealized_cum_pnl:
          newOverviewData?.portfolio_unrealized_cum_pnl!,
        portfolio_unrealized_cum_pnl_rate:
          newOverviewData?.portfolio_unrealized_cum_pnl_rate!,
      });
    }
  };

  const prevSlide = () => {
    if (currentSelected > 0) {
      setCurrentSelected(currentSelected - 1);
    }
  };

  const getColor = (snapshotToday: number) => {
    return snapshotToday < 0 ? "red" : "green";
  };

  return (
    <Card>
      <CardHeader
        sx={{
          fontSize: 20,
          position: "relative", // Make the header relative for absolute positioning within it
        }}
        title={
          <div
            style={{ width: "100%", textAlign: "center", position: "relative" }}
          >
            {currentSelected !== 0 ? (
              <>
                <IconButton
                  sx={{
                    position: "absolute",
                    left: 0,
                    paddingRight: "5vw",
                    paddingLeft: "1vw",
                    zIndex: 1, // Ensure it stays on top of the title text
                  }}
                  onClick={prevSlide}
                >
                  <KeyboardArrowLeftIcon color="primary" />
                </IconButton>
                {title.length < 10 ? title : title.split(" ")[0] + "..."}
              </>
            ) : isTranslation ? (
              getTextByLanguage("OVERVIEW", language)
            ) : (
              "Overview"
            )}
            {!overview_only && dataPortfolio && dataPortfolio.length > 0 && (
              <IconButton
                sx={{
                  position: "absolute",
                  right: 0,
                  paddingLeft: "5vw",
                  paddingRight: "1vw",
                  zIndex: 1, // Ensure it stays on top of the title text
                }}
                onClick={nextSlide}
              >
                <KeyboardArrowRightIcon color="primary" />
              </IconButton>
            )}
          </div>
        }
      />

      {overview_only && <Divider variant="middle" />}

      <CardContent sx={{ mt: -2, height: "22.75rem" }}>
        {snapshot.isLoading || portfolioSnapshot.isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: "8rem",
            }}
          >
            <Loading size={15} color="inherit" />
          </Box>
        ) : snapshot.isError || portfolioSnapshot.isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: color.red,
              fontWeight: "bold",
              marginTop: "8rem",
            }}
          >
            {snapshot.error?.message || portfolioSnapshot.error?.message}
          </Box>
        ) : (
          <>
            {(!overviewData || !newOverviewData) &&
            snapshotToday!.length > 0 &&
            dataPortfolio!.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: "8rem",
                }}
              >
                <Loading size={15} color="inherit" />
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    {Object.keys(overviewData || {}).length > 0 &&
                    Object.keys(newOverviewData || {}).length > 0 ? (
                      currentSelected === 0 ? (
                        <>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage("PNL", language)
                                : PL}
                            </TableCell>
                            <TableCell>
                              <strong
                                style={{
                                  color: getColor(
                                    parseFloat(
                                      newOverviewData!
                                        .portfolio_unrealized_cum_pnl!
                                    )
                                  ),
                                }}
                              >
                                {parseFloat(
                                  newOverviewData!.portfolio_unrealized_cum_pnl!
                                ) !== 0 ? (
                                  <>
                                    {(() => {
                                      const value = calculateEuroDollar(
                                        parseFloat(
                                          newOverviewData!
                                            .portfolio_unrealized_cum_pnl!
                                        ),
                                        euroDollar.currency,
                                        euroDollar.valueEuroDollar
                                      ).toFixed(2);

                                      if (euroDollar.currency === "$") {
                                        // Format pour Dollar avec le signe avant le montant
                                        return parseFloat(value) < 0
                                          ? `-$${Math.abs(parseFloat(value))}`
                                          : `$${value}`;
                                      } else if (euroDollar.currency === "€") {
                                        // Format pour Euro avec le signe après le montant
                                        return parseFloat(value) < 0
                                          ? `-${Math.abs(parseFloat(value))}€`
                                          : `${value}€`;
                                      }
                                      return value;
                                    })()}
                                  </>
                                ) : (
                                  <PriceHelper />
                                )}
                              </strong>

                              <br />
                              <strong
                                style={{
                                  color: getColor(
                                    (parseFloat(
                                      newOverviewData?.portfolio_unrealized_cum_pnl!
                                    ) /
                                      parseFloat(
                                        newOverviewData?.portfolio_investment!
                                      )) *
                                      100
                                  ),
                                }}
                              >
                                {parseFloat(
                                  newOverviewData?.portfolio_unrealized_cum_pnl!
                                ) !== 0 &&
                                parseFloat(
                                  newOverviewData?.portfolio_investment!
                                ) !== 0 ? (
                                  (
                                    (parseFloat(
                                      newOverviewData?.portfolio_unrealized_cum_pnl!
                                    ) /
                                      parseFloat(
                                        newOverviewData?.portfolio_investment!
                                      )) *
                                    100
                                  ).toFixed(2) + " % "
                                ) : (
                                  <PriceHelper />
                                )}
                              </strong>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage("NET_WORTH", language)
                                : NET_WORTH}
                            </TableCell>
                            {parseFloat(newOverviewData?.crypto_net_worth!) >
                            0 ? (
                              <TableCell>
                                {euroDollar.currency === "$" && "$"}
                                {calculateEuroDollar(
                                  parseFloat(
                                    newOverviewData?.crypto_net_worth!
                                  ),
                                  euroDollar.currency,
                                  euroDollar.valueEuroDollar
                                ).toFixed(2)}{" "}
                                {euroDollar.currency === "€" && "€"}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <PriceHelper />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage("INVESTMENT", language)
                                : INVESTMENT}
                            </TableCell>
                            {parseFloat(
                              newOverviewData?.portfolio_investment!
                            ) > 0 ? (
                              <TableCell>
                                {euroDollar.currency === "$" && "$"}
                                {calculateEuroDollar(
                                  parseFloat(
                                    newOverviewData?.portfolio_investment!
                                  ),
                                  euroDollar.currency,
                                  euroDollar.valueEuroDollar
                                ).toFixed(2)}{" "}
                                {euroDollar.currency === "€" && "€"}
                              </TableCell>
                            ) : (
                              <TableCell>
                                {" "}
                                <PriceHelper />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage("FIAT_BALANCE", language)
                                : FIAT_BALANCE}
                            </TableCell>
                            <TableCell>
                              {euroDollar.currency === "$" && "$"}
                              {calculateEuroDollar(
                                parseFloat(
                                  overviewData?.portfolio_fiat_balance!
                                ),
                                euroDollar.currency,
                                euroDollar.valueEuroDollar
                              ).toFixed(2)}{" "}
                              {euroDollar.currency === "€" && "€"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage(
                                    "STABLE_COIN_BALANCE",
                                    language
                                  )
                                : STABLE_COIN_BALANCE}
                            </TableCell>
                            <TableCell>
                              {euroDollar.currency === "$" && "$"}
                              {calculateEuroDollar(
                                parseFloat(
                                  overviewData?.portfolio_stable_coin_balance!
                                ),
                                euroDollar.currency,
                                euroDollar.valueEuroDollar
                              ).toFixed(2)}{" "}
                              {euroDollar.currency === "€" && "€"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {isTranslation
                                ? getTextByLanguage(
                                    "PORTFOLIO_BALANCE",
                                    language
                                  )
                                : PORTFOLIO_BALANCE}
                            </TableCell>
                            <TableCell>
                              {euroDollar.currency === "$" && "$"}
                              {calculateEuroDollar(
                                parseFloat(newOverviewData?.crypto_net_worth!) +
                                  parseFloat(
                                    overviewData?.portfolio_fiat_balance!
                                  ) +
                                  parseFloat(
                                    overviewData?.portfolio_stable_coin_balance!
                                  ),
                                euroDollar.currency,
                                euroDollar.valueEuroDollar
                              ).toFixed(2)}{" "}
                              {euroDollar.currency === "€" && "€"}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell>{PL}</TableCell>
                            <TableCell>
                              <strong
                                style={{
                                  color: getColor(
                                    parseFloat(
                                      selectData?.portfolio_unrealized_cum_pnl!
                                    )
                                  ),
                                }}
                              >
                                {!isNaN(
                                  parseFloat(selectData?.avg_purchase_price!)
                                ) &&
                                parseFloat(selectData?.avg_purchase_price!) >
                                  0 &&
                                livePrice[selectData?.asset!] ? (
                                  <>
                                    {(() => {
                                      const value = calculateEuroDollar(
                                        parseFloat(
                                          selectData?.portfolio_unrealized_cum_pnl!
                                        ),
                                        euroDollar.currency,
                                        euroDollar.valueEuroDollar
                                      ).toFixed(2);

                                      if (euroDollar.currency === "$") {
                                        // Format pour Dollar avec le signe avant le montant
                                        return parseFloat(value) < 0
                                          ? `-$${Math.abs(parseFloat(value))}`
                                          : `$${value}`;
                                      } else if (euroDollar.currency === "€") {
                                        // Format pour Euro avec le signe après le montant
                                        return parseFloat(value) < 0
                                          ? `-${Math.abs(parseFloat(value))}€`
                                          : `${value}€`;
                                      }
                                      return value;
                                    })()}
                                  </>
                                ) : (
                                  <PriceHelper />
                                )}
                              </strong>

                              <br />
                              <strong
                                style={{
                                  color: getColor(
                                    parseFloat(
                                      selectData?.portfolio_unrealized_cum_pnl_rate!
                                    )
                                  ),
                                }}
                              >
                                {!isNaN(
                                  parseFloat(selectData?.avg_purchase_price!)
                                ) &&
                                parseFloat(selectData?.avg_purchase_price!) >
                                  0 &&
                                livePrice[selectData?.asset!] ? (
                                  `${selectData?.portfolio_unrealized_cum_pnl_rate} %`
                                ) : (
                                  <PriceHelper />
                                )}
                              </strong>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{NET_WORTH}</TableCell>
                            {livePrice[selectData?.asset!] ? (
                              <TableCell>
                                {euroDollar.currency === "$" && "$"}
                                {calculateEuroDollar(
                                  parseFloat(selectData?.crypto_net_worth!),
                                  euroDollar.currency,
                                  euroDollar.valueEuroDollar
                                ).toFixed(2)}
                                {euroDollar.currency === "€" && "€"}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <PriceHelper />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>{INVESTMENT}</TableCell>
                            {!isNaN(
                              parseFloat(selectData?.avg_purchase_price!)
                            ) &&
                            parseFloat(selectData?.avg_purchase_price!) > 0 ? (
                              <TableCell>
                                {euroDollar.currency === "$" && "$"}
                                {calculateEuroDollar(
                                  isNaN(
                                    parseFloat(
                                      selectData?.portfolio_investment!
                                    )
                                  )
                                    ? parseFloat("0")
                                    : parseFloat(
                                        selectData?.portfolio_investment!
                                      ),
                                  euroDollar.currency,
                                  euroDollar.valueEuroDollar
                                ).toFixed(2)}{" "}
                                {euroDollar.currency === "€" && "€"}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <PriceHelper />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>{TOKEN_BALANCE}</TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              {parseFloat(selectData?.owned_assets!).toFixed(8)}
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          marginTop: "7rem",
                        }}
                      >
                        {NO_DATA}
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}{" "}
          </>
        )}
      </CardContent>
    </Card>
  );
}
