import { LIVEPRICE } from "../constantes/query";
import { useQuery } from "@tanstack/react-query";
import { getLivePrice } from "../services/other";

export const useGetLivePrice = () => {
  return useQuery({
    queryKey: LIVEPRICE,
    queryFn: () => getLivePrice(),
    refetchInterval: 5 * 60 * 1000, // Rafraîchir toutes les 5 secondes
    refetchOnWindowFocus: "always", // Forcer un rafraîchissement au retour du focus
    staleTime: 5 * 60 * 1000, // Considérer les données comme obsolètes après 5 secondes
  });
};
