import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Loading from "../shared/Loading";
import color from "../constantes/color";
import { ERROR } from "../constantes/app";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AggregatedDataSnapshot } from "../types/index";
import { useFilterSnapshots } from "../hook/useFilterSnapshots";
import { useInitializeData } from "../hook/useInitializeDataDashboard";
import { selectDisplayPortfolio, selectUser } from "../redux/selector";
import { useGetUserConnectedOrSelected } from "../hook/useGetUserConnectedOrSelected";

// Components
import OverView from "../features/Overview";
import AssetByGroup from "../features/AssetByGroup";
import AssetListing from "../features/AssetListing";
import CumulativePnl from "../features/CumulativePnl";
import AssetAllocation from "../features/AssetAllocation";
import AssetDistrubition from "../features/AssetDistrubition";

export default function Dashboard() {
  const userName = useParams<{ userName: string }>().userName;

  const dispatch = useDispatch();

  // date of the day
  const today = new Date().toISOString().split("T")[0];

  // state
  const [title, setTitle] = useState<string>("overview");
  const [selectData, setSelectData] = useState<
    AggregatedDataSnapshot | undefined
  >();

  // Récupère tous les snapshots nécessaires et les valeurs mémorisées
  const { users, asset, snapshot, portfolioSnapshot } = useInitializeData(
    dispatch,
    userName
  );

  // selectors
  const displayPortFolio = useSelector(selectDisplayPortfolio);
  const userSelected = useGetUserConnectedOrSelected(userName);
  const userConnected = useSelector(selectUser);

  // Filtrage des snapshots
  const { snapshotToday, portfolioSnapshotToday } = useFilterSnapshots({
    snapshot,
    portfolioSnapshot,
    userSelected,
    displayPortFolio,
    today,
  });

  // Affiche un écran de chargement ou d'erreur
  if (users.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading size={20} />
      </Box>
    );
  }

  if (users.isError) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          color: color.red,
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        {ERROR} : {users.error?.message}
      </Box>
    );
  }

  

  return (
    <>
      <Grid container spacing={2}>
        {userName || !userConnected?.data?.is_manager ? (
          <>
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 3,
              }}
            >
              <OverView
                snapshotToday={snapshotToday}
                snapshot={snapshot}
                selectData={selectData}
                setSelectData={setSelectData}
                title={title}
                setTitle={setTitle}
                dataPortfolio={portfolioSnapshotToday}
                portfolioSnapshot={portfolioSnapshot}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetAllocation asset={asset} snapshotToday={snapshotToday} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetDistrubition asset={asset} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <AssetByGroup asset={asset} />
            </Grid>{" "}
          </>
        ) : (
          <>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetAllocation asset={asset} />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetDistrubition asset={asset} />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4 }}>
              <AssetByGroup asset={asset} />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 12,
                md: 12,
              }}
            >
              <OverView
                snapshotToday={snapshotToday}
                snapshot={snapshot}
                selectData={selectData}
                setSelectData={setSelectData}
                title={title}
                setTitle={setTitle}
                dataPortfolio={portfolioSnapshotToday}
                portfolioSnapshot={portfolioSnapshot}
              />
            </Grid>
          </>
        )}
      </Grid>
      {(userName || !userConnected?.data?.is_manager) && (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CumulativePnl
              title="Asset Net Worth"
              selectData={selectData!}
              type="networth"
              cryptoSelected={title}
              snapshot={snapshot}
              snapshotToday={snapshotToday}
              portfolioSnapshot={portfolioSnapshot}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <CumulativePnl
              title="Cumulative PNL(%)"
              selectData={selectData!}
              type="percent"
              cryptoSelected={title}
              snapshot={snapshot}
              snapshotToday={snapshotToday}
              portfolioSnapshot={portfolioSnapshot}
            />
          </Grid>
        </Grid>
      )}
      {(userName || !userConnected?.data?.is_manager) && (
        <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 1 }}>
          <Grid size={{ xs: 12, sm: 12 }}>
            <AssetListing
              asset={asset}
              snapshot={snapshotToday}
              isLoadingSnapshot={snapshot.isLoading}
              isErrorSnapshot={snapshot.isError}
              errorSnapshot={snapshot.error}
              isAllData={!displayPortFolio.isActive}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
