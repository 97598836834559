import { useMemo } from "react";
import Highcharts from "highcharts";
import Box from "@mui/material/Box";
import { useManageData } from "./hook";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
import Loading from "../../shared/Loading";
import color from "../../constantes/color";
import { NO_DATA } from "../../constantes/app";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import PriceHelper from "../../shared/PriceHelper";
import CardContent from "@mui/material/CardContent";
import { AggregatedDataSnapshot } from "../../types";
import { UseQueryResult } from "@tanstack/react-query";
import HighchartsReact from "highcharts-react-official";
import { selectEuroDollar } from "../../redux/selector";
import { calculateEuroDollar } from "../../utils/calcul";
import Accessibility from "highcharts/modules/accessibility";
import { PortofolioSnapshot, Snapshot } from "../../types/snapshot";

Accessibility(Highcharts);

export default function CumulativePnl({
  title,
  type,
  selectData,
  cryptoSelected,
  snapshot,
  snapshotToday,
  portfolioSnapshot,
  isHeader = true,
}: {
  title: string;
  selectData: AggregatedDataSnapshot;
  type: "percent" | "simple" | "networth";
  cryptoSelected: string;
  snapshot: UseQueryResult<Snapshot[], Error>;
  snapshotToday: Snapshot[] | undefined;
  portfolioSnapshot: UseQueryResult<PortofolioSnapshot[], Error>;
  isHeader?: boolean;
}) {
  const euroDollar = useSelector(selectEuroDollar);
  const { finalDataCrypto, finalDataOverview } = useManageData(
    snapshot,
    portfolioSnapshot,
    euroDollar,
    cryptoSelected,
    type,
    selectData
  );

  const renderPercentage = () => {
    if (selectData.asset === "Overview") {
      const unrealizedCumPnl = parseFloat(
        selectData.portfolio_unrealized_cum_pnl
      );
      const portfolioInvestment = parseFloat(selectData.portfolio_investment);

      if (unrealizedCumPnl !== 0 && portfolioInvestment !== 0) {
        const percentage = (unrealizedCumPnl / portfolioInvestment) * 100;
        return (
          (percentage > 0 ? "+" : "-") + Math.abs(percentage).toFixed(2) + "%"
        );
      } else {
        return <PriceHelper />;
      }
    } else {
      const cumPnlRate = parseFloat(
        selectData.portfolio_unrealized_cum_pnl_rate
      );
      if (
        cumPnlRate !== 0 ||
        (cumPnlRate === 0 && parseFloat(selectData.portfolio_investment) > 0)
      ) {
        return (
          (cumPnlRate > 0 ? "+" : "-") + Math.abs(cumPnlRate).toFixed(2) + "%"
        );
      } else {
        return <PriceHelper />;
      }
    }
  };

  const renderSimple = () => {
    const unrealizedCumPnl = parseFloat(
      selectData.portfolio_unrealized_cum_pnl
    );
    if (unrealizedCumPnl !== 0) {
      return (
        <>
          {unrealizedCumPnl >= 0 ? "+" : "-"}
          {euroDollar.currency === "$" && "$"}
          {calculateEuroDollar(
            Math.abs(unrealizedCumPnl),
            euroDollar.currency,
            euroDollar.valueEuroDollar
          ).toFixed(2)}
          {euroDollar.currency === "€" && "€"}
        </>
      );
    } else {
      return <PriceHelper />;
    }
  };

  const renderOther = () => {
    const cryptoNetWorth = parseFloat(selectData.crypto_net_worth);
    if (cryptoNetWorth > 0) {
      return (
        <>
          {cryptoNetWorth >= 0 ? "+" : "-"}
          {euroDollar.currency === "$" && "$"}
          {calculateEuroDollar(
            Math.abs(cryptoNetWorth),
            euroDollar.currency,
            euroDollar.valueEuroDollar
          ).toFixed(2)}
          {euroDollar.currency === "€" && "€"}
        </>
      );
    } else {
      return <PriceHelper />;
    }
  };

  const color_ =
    type === "percent"
      ? selectData?.asset !== "Overview"
        ? parseFloat(selectData?.portfolio_unrealized_cum_pnl_rate!) >= 0
          ? color.green
          : color.red
        : parseFloat(selectData?.portfolio_unrealized_cum_pnl) !== 0 &&
          parseFloat(selectData?.portfolio_investment) !== 0 &&
          parseFloat(selectData?.portfolio_unrealized_cum_pnl) /
            parseFloat(selectData?.portfolio_investment) >
            0
        ? color.green
        : color.red
      : type === "simple"
      ? parseFloat(selectData?.portfolio_unrealized_cum_pnl!) >= 0
        ? color.green
        : color.red
      : parseFloat(selectData?.crypto_net_worth!) >= 0
      ? color.green
      : color.red;

  const chartOptions = useMemo(
    () => ({
      credits: { enabled: false },
      chart: { type: "spline", height: 255 },
      title: { text: "" },
      plotOptions: {
        series: { marker: { enabled: true, radius: 1, symbol: "circle" } },
      },
      xAxis: {
        minPadding: 0.04,
        maxPadding: 0.04,
        type: "datetime",
        title: { text: "Date" },
      },
      yAxis: { title: { text: "" } },
      legend: { enabled: false },
      series:
        cryptoSelected === "overview"
          ? [
              {
                name: finalDataOverview.name,
                data: finalDataOverview.data,
                tooltip: {
                  valueSuffix: type === "percent" ? "%" : euroDollar.currency,
                },
              },
            ]
          : [
              {
                name: cryptoSelected,
                data: finalDataCrypto.data,
                tooltip: {
                  valueSuffix: type === "percent" ? "%" : euroDollar.currency,
                },
              },
            ],
    }),
    [finalDataCrypto, finalDataOverview, euroDollar, type, cryptoSelected]
  );

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          isHeader &&
          snapshot.data &&
          selectData &&
          Object.keys(selectData).length > 0 && (
            <Typography
              sx={{
                color: color_,
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "right",
                padding: "0.5vw 1vw 0.5vw 0.5vw",
              }}
            >
              {type === "percent" && renderPercentage()}
              {type === "simple" && renderSimple()}
              {type !== "percent" && type !== "simple" && renderOther()}
            </Typography>
          )
        }
      />
      <CardContent sx={{ mt: -4, height: "20.75rem" }}>
        {snapshot.isLoading || portfolioSnapshot.isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginTop: "8rem",
            }}
          >
            <Loading size={15} color="inherit" />
          </Box>
        ) : snapshot.isError || portfolioSnapshot.isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              color: color.red,
              marginTop: "8rem",
            }}
          >
            {snapshot.error?.message || portfolioSnapshot.error?.message}
          </Box>
        ) : (
          <>
            {!selectData && snapshotToday!.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: "8rem",
                }}
              >
                <Loading size={15} color="inherit" />
              </Box>
            ) : snapshot.data?.length || portfolioSnapshot.data?.length ? (
              selectData && Object.keys(selectData).length > 0 ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginTop: "7rem",
                  }}
                >
                  {NO_DATA}
                </Box>
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  marginTop: "7rem",
                }}
              >
                {NO_DATA}
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
