import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Toaster } from "react-hot-toast";
import { User } from "../../../types/user";
import Divider from "@mui/material/Divider";
import Loading from "../../../shared/Loading";
import { TInputsEditUserOrTeam } from "./type";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useUpdateUserOrTeam } from "../hook/useUpdate";
import { SubmitHandler, useForm } from "react-hook-form";
import { EDIT, CANCEL, EDIT_USER, EDIT_MANAGER } from "../../../constantes/app";

export default function EditClientOrTeam({
  open,
  handleClose,
  isManager,
  data_,
}: {
  open: boolean;
  handleClose: () => void;
  isManager?: boolean;
  data_: User;
}) {
  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const { register, handleSubmit, watch, reset } =
    useForm<TInputsEditUserOrTeam>();

  const [initialValues, setInitialValues] = useState<TInputsEditUserOrTeam>({
    profile_image_url: data_.profile_image_url,
  });

  useEffect(() => {
    setInitialValues({
      profile_image_url: data_.profile_image_url,
    });
  }, [data_]);

  const isFormUnchanged =
    watch("profile_image_url") === initialValues.profile_image_url;

  const update = useUpdateUserOrTeam(reset, handleClose, isManager!);

  const onSubmit: SubmitHandler<TInputsEditUserOrTeam> = (data) => {
    data.id = data_.id;
    update.mutate(data);
  };

  return (
    <React.Fragment>
      <Toaster />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleBackdropClick}
        sx={{
          marginTop: -50,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
            }}
          >
            {isManager ? EDIT_MANAGER : EDIT_USER} : {data_.username}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  disabled
                  defaultValue={data_.username}
                />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Profile image url"
                  variant="outlined"
                  fullWidth
                  defaultValue={data_.profile_image_url}
                  type="url"
                  {...register("profile_image_url", { required: true })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              {CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={update.isPending || isFormUnchanged}
            >
              {update.isPending ? <Loading size={10} color="inherit" /> : EDIT}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
