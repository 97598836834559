import {
  SET_CURRENCY,
  SET_TIME_MACHINE,
  SET_DISPLAY_PORTFOLIO,
} from "../../../constantes/redux";
import dayjs from "dayjs";
import * as React from "react";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import color from "../../../constantes/color";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { getTextByLanguage } from "../../../utils/function";
import FormControlLabel from "@mui/material/FormControlLabel";
import PreviewDateToDateReport from "../Preview/DateToDateReport";
import { GENERATE_REPORT, PREVIEW } from "../../../constantes/app";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export default function GenerateDateToDateReport({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const dispatch = useDispatch();

  const today = dayjs();
  const currentYear = new Date().getFullYear();

  const [dateFrom, setDateFrom] = React.useState(
    new Date(Date.UTC(currentYear - 2, 11, 31)) // Force UTC
  );
  const [dateTo, setDateTo] = React.useState(
    new Date(Date.UTC(currentYear - 1, 11, 31)) // Force UTC
  );

  const [language, setLanguage] = React.useState<"fr" | "en">("fr");
  const [title, setTitle] = React.useState<string>(
    `${getTextByLanguage(
      "DEFAULT_TITLE",
      language
    )} ${dateFrom.getFullYear()} - ${dateTo.getFullYear()}`
  );
  const [surname, setSurname] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [includeTransactions, setIncludesTransactions] =
    React.useState<boolean>(true);

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "fr" : "en"));
  };

  React.useEffect(() => {
    setTitle(
      `${getTextByLanguage(
        "DEFAULT_TITLE",
        language
      )} ${dateFrom.getFullYear()} - ${dateTo.getFullYear()}`
    );
  }, [dateFrom, dateTo, language]);

  React.useEffect(() => {
    dispatch({
      type: SET_CURRENCY,
      payload: { currency: language.startsWith("fr") ? "€" : "$" },
    });
  }, [language, dispatch]);

  return (
    <>
      {openPreview && (
        <PreviewDateToDateReport
          open={openPreview}
          handleClose={() => setOpenPreview(false)}
          titleReport={title}
          name={name}
          surname={surname}
          comment={comment}
          language={language}
          dateFrom={dateFrom.toISOString().split("T")[0]}
          dateTo={dateTo.toISOString().split("T")[0]}
          includeTransactions={includeTransactions}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          {GENERATE_REPORT}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                value={title}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                  value={dateFrom}
                  onChange={(newValue) => {
                    if (newValue) {
                      console.log("new value from", newValue);
                      try {
                        setDateFrom(newValue);
                      } catch (error) {
                        console.error("Invalid date:", error);
                      }
                    } else {
                      console.warn("Date is invalid or null");
                    }
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  shouldDisableDate={(date) => dayjs(date).isAfter(today)}
                  value={dateTo}
                  onChange={(newValue) => {
                    if (newValue) {
                      console.log("new value to", newValue);
                      try {
                        setDateTo(newValue);
                      } catch (error) {
                        console.error("Invalid date:", error);
                      }
                    } else {
                      console.warn("Date is invalid or null");
                    }
                  }}
                  sx={{
                    width: "100%",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Surname"
                variant="outlined"
                fullWidth
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>

            <Grid
              size={{
                xs: 12,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Comment"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={includeTransactions} />}
                  label="Include transactions"
                  onChange={(event, checked) => {
                    setIncludesTransactions(checked);
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                marginRight={2}
              >
                <Typography
                  sx={{
                    color: `${color.black}`,
                    fontWeight: "bold",
                  }}
                >
                  FR
                </Typography>
                <Switch checked={language === "en"} onChange={toggleLanguage} />
                <Typography
                  sx={{
                    color: `${color.black}`,
                    fontWeight: "bold",
                  }}
                >
                  EN
                </Typography>
              </Stack>
            </Grid>
            <Grid
              size={{
                xs: 12,
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({
                    type: SET_DISPLAY_PORTFOLIO,
                    payload: { isActive: false, date: null },
                  });
                  dispatch({
                    type: SET_TIME_MACHINE,
                    payload: {
                      isActive: false,
                      startDate: null,
                      endDate: null,
                    },
                  });
                  setOpenPreview(true);
                }}
                sx={{
                  width: "50%",
                  marginTop: "10px",
                }}
                disabled={!surname || !name}
              >
                {PREVIEW}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
