import {
  calculateExitPlanCumPnLRate,
  calculateExitPlanCumPnLValue,
} from "../calcul";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Loading from "../../../shared/Loading";
import color from "../../../constantes/color";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import toast, { Toaster } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import { DETAILS } from "../../../constantes/app";
import { TExitPlan } from "../../../types/exitPlan";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetAsset } from "../../../hook/useGetAsset";
import withReactContent from "sweetalert2-react-content";
import { useCalculateAvgAcquisitionCost } from "../hooks";
import { EXIT_PLAN_KEY } from "../../../constantes/query";
import { calculateEuroDollar } from "../../../utils/calcul";
import { deleteExitPlan } from "../../../services/exitPlan";
import { useGetLogoCrypto } from "../../../hook/useGetLogoCrypto";
import { selectAssetData, selectEuroDollar } from "../../../redux/selector";
import { useManageUserSelectedID } from "../../../hook/useManageUserSelectedID";

export default function DetailsListExitPlan({ row }: { row: TExitPlan }) {
  const userName = useParams<{ userName: string }>().userName;

  const MySwal = withReactContent(Swal);
  const queryClient = useQueryClient();
  const euroDollar = useSelector(selectEuroDollar);
  const logo = useGetLogoCrypto(row.symbol);
  const userId = useManageUserSelectedID(userName);
  const asset = useGetAsset(`${userId}`, row.symbol);
  const { avgAcquisitionCost, allTimeSold, avgSellingPrice } =
    useCalculateAvgAcquisitionCost(asset);

  const assetData = useSelector(selectAssetData).data?.filter(
    (item) => item.asset === row.symbol
  )[0];

  let finalPnLValue = 0;
  let finalPnLRate = 0;

  const processedTakeProfits = row.take_profits.map((item) => ({
    token_sold:
      (parseFloat(assetData?.owned_assets!) * item.distribution_percent) / 100,
    tp_price: item.tp_price,
    distribution_percent: item.distribution_percent,
  }));

  if (row.take_profits.length > 0) {
    finalPnLValue = calculateExitPlanCumPnLValue(
      avgAcquisitionCost!,
      avgSellingPrice!,
      allTimeSold!,
      processedTakeProfits
    );

    finalPnLRate =
      calculateExitPlanCumPnLRate(
        avgAcquisitionCost!,
        avgSellingPrice!,
        allTimeSold!,
        processedTakeProfits
      ) * 100;
  }

  return (
    <>
      <Toaster />
      <TableRow
        key={row.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              {logo.isLoading ? (
                <Loading color="inherit" />
              ) : (
                <Avatar
                  src={logo.data?.logo}
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    borderWidth: 2,
                    marginRight: 1,
                  }}
                />
              )}
            </Box>
            <strong>{row.symbol}</strong>
          </Box>
        </TableCell>
        <TableCell>{row.take_profits.length}</TableCell>
        <TableCell>
          <strong
            style={{
              color: finalPnLValue >= 0 ? color.green : color.red,
            }}
          >
            {calculateEuroDollar(
              finalPnLValue,
              euroDollar.currency,
              euroDollar.valueEuroDollar
            ).toFixed(2)}
            {`${euroDollar.currency}`}
          </strong>{" "}
          |{" "}
          <strong
            style={{
              color: finalPnLRate >= 0 ? color.green : color.red,
            }}
          >
            {finalPnLRate.toFixed(2)}%
          </strong>
        </TableCell>
        <TableCell>
          <NavLink
            to={
              userName
                ? `/exit-plan/details/${userName}/${row.id}`
                : `/exit-plan/details/${row.id}`
            }
            style={{
              textDecoration: "none",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginRight: 1,
              }}
            >
              {DETAILS}
            </Button>
          </NavLink>
          <Tooltip title="Delete">
            <IconButton
              size="small"
              aria-label="delete"
              onClick={() => {
                MySwal.fire({
                  text: `Are you sure you want to delete ${row.name} : ${row.symbol} exit plan?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteExitPlan(row.id)
                      .then(() => {
                        queryClient.invalidateQueries({
                          queryKey: [EXIT_PLAN_KEY],
                        });
                        toast.success(
                          `Exit plan ${row.name} : ${row.symbol} deleted successfully`
                        );
                      })
                      .catch((error) => {
                        toast.error(
                          `An error occurred while trying to delete ${row.name} : ${row.symbol} exit plan: ${error.message}`
                        );
                      });
                  }
                });
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
