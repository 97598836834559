import {
  USERNAME,
  JOINED_ON,
  UPDATE_PASSWORD,
  EDITIN_FORMATION,
} from "../../../constantes/app";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import color from "../../../constantes/color";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { selectUser } from "../../../redux/selector";
import GppGoodSharpIcon from "@mui/icons-material/GppGoodSharp";
import { convertDateFormatIso } from "../../../utils/converting";

export default function MyInformation({
  editInformation,
  setEditInformation,
  chagePassword,
  setChagePassword,
}: {
  editInformation: boolean;
  setEditInformation: (editInformation: boolean) => void;
  chagePassword: boolean;
  setChagePassword: (chagePassword: boolean) => void;
}) {
  const userData = useSelector(selectUser).data;
  return (
    <Card
      sx={{
        borderRadius: 3,
      }}
    >
      <CardHeader
        title={""}
        action={
          <>
            <Tooltip title={EDITIN_FORMATION}>
              <IconButton onClick={() => setEditInformation(!editInformation)}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={UPDATE_PASSWORD}>
              <IconButton onClick={() => setChagePassword(!chagePassword)}>
                <GppGoodSharpIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Avatar
            src={userData?.profile_image_url}
            sx={{
              width: 150,
              height: 150,
              borderRadius: 50,
              borderWidth: 2,
              marginRight: 1,
              border: `1px solid ${
                userData?.is_manager ? color.green : color.bluePrimary
              }`,
            }}
          />
        </Box>
        {userData?.is_manager && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography fontWeight={"bold"}>
                {userData?.first_name} {userData?.last_name}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Box>
            <Typography fontWeight={"bold"}>{userData?.email}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Box>
            <Typography fontWeight={"bold"}>
              {USERNAME} : {userData?.username}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography fontWeight={"bold"}>
              {JOINED_ON} {convertDateFormatIso(userData?.date_joined!)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
