import {
  ACTIONS,
  USER_NAME,
  NO_CLIENT,
  CREATED_AT,
  OUR_CLIENT_LIST,
  LIST_CLIENT_NO_MANAGER,
  LIST_CLIENT_NO_ACCOUNT_MANAGER,
} from "../../../constantes/app";
import { useState } from "react";
import Box from "@mui/material/Box";
import ClientInfo from "./ClientInfo";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import { User } from "../../../types/user";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CreateClientOrTeam from "../CreateClientOrTeam";
import TableContainer from "@mui/material/TableContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function Client({
  data,
  isNoManager,
  isNoAccountManager,
}: {
  data: User[] | undefined;
  isNoManager?: boolean;
  isNoAccountManager?: boolean;
}) {
  const [openAddClient, setOpenAddClient] = useState<boolean>(false);

  return (
    <>
      {openAddClient && (
        <CreateClientOrTeam
          open={openAddClient}
          handleClose={() => setOpenAddClient(false)}
        />
      )}
      <Card
        sx={{
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <CardHeader
          title={`${
            isNoManager
              ? `${LIST_CLIENT_NO_MANAGER}`
              : isNoAccountManager
              ? `${LIST_CLIENT_NO_ACCOUNT_MANAGER}`
              : `${OUR_CLIENT_LIST}`
          }`}
          action={
            !isNoManager &&
            !isNoAccountManager && (
              <IconButton
                sx={{
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={() => setOpenAddClient(true)}
              >
                <AddCircleIcon />
              </IconButton>
            )
          }
        />
        <Divider variant="middle" />
        <CardContent>
          {data && data.length > 0 ? (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {/* {CLIENT_INFO} */}
                      {USER_NAME}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {CREATED_AT}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {ACTIONS}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((client: User) => (
                    <ClientInfo
                      client={client}
                      isNomanager={isNoManager!}
                      isNoAccountManager={isNoAccountManager}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 2,
              }}
            >
              {NO_CLIENT}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
